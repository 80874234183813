<template>
  <v-container fluid>
    <v-row justify="center">
      <v-container style="max-width: 1280px">
        <v-row cols="12">
          <v-col cols="12">
            <p style="font-size: 24px">ご購入手続き</p>
          </v-col>
        </v-row>
        <v-row cols="12" dense>
          <v-col coks="12" align="center">
            <v-card>
              <v-card-title class="mb-0 pb-0">
                <p class="mb-0 pb-0">
                  お届け先
                </p>
              </v-card-title>
              <v-card-text style="padding-left:100px">
                <v-radio-group v-model="selectedOption"
                               v-if="this.$store.state.logininfo.userId != '' && this.$store.state.logininfo.userId != null">
                  <v-radio label="ご登録の住所に送る" value="1"></v-radio>
                  <div>
                    <p class="pl-8" v-show="this.selectedOption == 1">〒{{
                        this.postcode
                      }}&nbsp;&nbsp;&nbsp;&nbsp;{{ this.address }}</p>
                    <p class="pl-8" style="height: 10px"></p>
                  </div>
                  <v-radio label="その他の住所に送る" value="2"></v-radio>
                </v-radio-group>
                <div
                    v-if="this.selectedOption == '2' || this.$store.state.logininfo.userId == ''|| this.$store.state.logininfo.userId == null">
                  <v-row dense>
                    <v-col cols="3">
                      <p class="required">お名前</p>
                    </v-col>
                    <v-col cols="3" align="start">
                      <v-text-field
                          outlined
                          dense
                          label="姓"
                          v-model="firstname"
                          :error-messages="firstnameErrorMsg"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="3" align="start">
                      <v-text-field
                          outlined
                          dense
                          label="名"
                          v-model="lastname"
                          :error-messages="lastnameErrorMsg"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col cols="3">
                      <p class="required">フリガナ</p>
                    </v-col>
                    <v-col cols="3" align="start">
                      <v-text-field
                          outlined
                          dense
                          label="セイ"
                          v-model="firstnamekana"
                          :error-messages="firstnamekanaErrorMsg"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="3" align="start">
                      <v-text-field
                          outlined
                          dense
                          label="メイ"
                          v-model="lastnamekana"
                          :error-messages="lastnamekanaErrorMsg"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col cols="3">
                      <p class="required">郵便番号</p>
                    </v-col>
                    <v-col cols="3" align="start">
                      <v-text-field
                          outlined
                          dense
                          label="ハイフン（-）不要"
                          v-model="postcode"
                          :error-messages="postCodeErrorMsg"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="3">
                      <v-btn
                          rounded
                          color="blue"
                          dark
                          @click="autoAddress"
                      >
                        郵便番号検索
                      </v-btn>
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col cols="3">
                      <p class="required">住所</p>
                    </v-col>
                    <v-col cols="6" align="start">
                      <v-text-field
                          style="max-width: 600px"
                          outlined
                          dense
                          v-model="address"
                          :error-messages="addressErrorMsg"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="1">
                    </v-col>
                    <v-col cols="3">
                    </v-col>
                    <v-col cols="6" align="start">
                      <v-text-field
                          style="max-width: 600px"
                          outlined
                          dense
                          v-model="address1"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col cols="3">
                      <p class="required">電話番号</p>
                    </v-col>
                    <v-col cols="3" align="start">
                      <v-text-field
                          outlined
                          dense
                          label="ハイフン（-）不要"
                          v-model="phone"
                          :error-messages="phoneErrorMsg"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col cols="3">
                      <p class="required">メールアドレス</p>
                    </v-col>
                    <v-col cols="6" align="start">
                      <v-text-field
                          outlined
                          dense
                          label="info@example.com"
                          v-model="mailaddress"
                          :error-messages="mailaddressErrorMsg"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row cols="12" class="mt-10" dense>
          <v-col coks="12" align="center">
            <v-card>
              <v-card-title class="mb-0 pb-0">
                <p class="mb-0 pb-0">決済方法</p>
              </v-card-title>
              <v-card-text style="padding-left:100px">
                <v-radio-group
                    v-model="radios"
                    mandatory
                >
                  <v-radio
                      label="代引き"
                      value="0"
                  ></v-radio>
                  <p class="infoMsg">商品代金 1 ～ 99,999円 代引手数料 550円 </p>
                  <p class="infoMsg">商品代金 100000 ～ 300,000円 代引手数料 1,100円</p>
                  <p style="height: 10px"></p>
                  <v-radio
                      label="銀行振込"
                      value="1"
                      style="margin-top: 16px"
                  ></v-radio>
                  <p class="infoMsg">▼振込先情報</p>
                  <p class="infoMsg">【 金 融 機 関 名】PayPay銀行</p>
                  <p class="infoMsg">【 支 店 名 】ビジネス営業部</p>
                  <p class="infoMsg">【 口 座 種 別 】普通預金</p>
                  <p class="infoMsg">【 口 座 番 号 】8161978</p>
                  <p class="infoMsg">【 口 座 名 義 】カ）ミナモト</p>
                  <p class="infoMsg">※振込手数料はお客様負担でお願いします。</p>
                  <p class="infoMsg">※3営業日以内にご入金が確認できない場合は、ご注文はキャンセルになります。</p>
                  <p class="infoMsg">※お急ぎの方は「代金引換」をお選びください。</p>
                </v-radio-group>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row cols="12" class="mt-10" dense>
          <v-col coks="12" align="center">
            <v-card>
              <v-card-title class="mb-0 pb-0">
                <p class="mb-0 pb-0">備考</p>
              </v-card-title>
              <v-card-text style="padding-left:100px">
                <v-col cols="12" align="start">
                  <v-text-field
                      dense
                      label="配送希望時間帯など"
                      v-model="note"
                  ></v-text-field>
                </v-col>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row dense style="margin-top:50px;padding-bottom: 20px">
          <v-col align="center">
            <v-btn
                rounded
                color="blue"
                dark
                width="200px"
                @click="goToPayConfirm()">
              ご注文の確認
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-row>
  </v-container>
</template>

<script>
import {mapGetters} from 'vuex'
import {checkMailAddress, checkPhoneNumber, checkPostCode, fixPrice} from "@/utils/common";
import {autoAddress, getUserInfo} from '@/domain/api/user'
import {urlImgHead} from "@/domain/RestClient"

export default {
  name: 'Pay',

  data: () => ({
    selectedOption: "1",
    urlImgHead,
    headers: [
      {
        text: '商品情報',
        align: 'start',
        sortable: false,
        value: 'name',
      },
      {text: '価格（税込）', sortable: false, value: 'price', width: '180px'},
      {text: '数量', sortable: false, value: 'count', width: '100px'},
      {text: '小計', sortable: false, value: 'goodPrice', width: '180px'}
    ],
    firstname: null,
    lastname: null,
    firstnamekana: null,
    lastnamekana: null,
    phone: null,
    mailaddress: null,
    postcode: null,
    address: null,
    address1: "",
    note: null,
    radios: "0",
    firstnameErrorMsg: "",
    lastnameErrorMsg: "",
    firstnamekanaErrorMsg: "",
    lastnamekanaErrorMsg: "",
    postCodeErrorMsg: "",
    phoneErrorMsg: "",
    addressErrorMsg: "",
    mailaddressErrorMsg: ""
  }),
  watch: {
    async selectedOption(newValue) {
      if (newValue == 1) {
        const userData = await getUserInfo(this.$store.state.logininfo.userId);
        this.firstname = userData.name.split(" ")[0];
        this.lastname = userData.name.split(" ")[1];
        this.firstnamekana = userData.namekana.split(" ")[0];
        this.lastnamekana = userData.namekana.split(" ")[1];
        this.phone = userData.phone;
        this.mailaddress = userData.mailaddress;
        this.postcode = userData.postcode;
        this.address = userData.address;
      } else {
        this.firstname = "";
        this.lastname = "";
        this.firstnamekana = "";
        this.lastnamekana = "";
        this.phone = "";
        this.mailaddress = "";
        this.postcode = "";
        this.address = "";
      }
    }
  },
  async mounted() {
    if (this.$store.state.orderinfo.orderDetail != null) {
      const tempData = this.$store.state.orderinfo.orderDetail;
      this.firstname = tempData.firstname;
      this.lastname = tempData.lastname;
      this.firstnamekana = tempData.firstnamekana;
      this.lastnamekana = tempData.lastnamekana;
      this.phone = tempData.phone;
      this.mailaddress = tempData.mailaddress;
      this.postcode = tempData.postcode;
      this.address = tempData.address;
      this.note = tempData.note;
      return;
    }

    if (this.$store.state.logininfo.userId != null && this.$store.state.logininfo.userId != "") {
      const userData = await getUserInfo(this.$store.state.logininfo.userId);
      this.firstname = userData.name.split(" ")[0];
      this.lastname = userData.name.split(" ")[1];
      this.firstnamekana = userData.namekana.split(" ")[0];
      this.lastnamekana = userData.namekana.split(" ")[1];
      this.phone = userData.phone;
      this.mailaddress = userData.mailaddress;
      this.postcode = userData.postcode;
      this.address = userData.address;
    }
  },
  computed: {
    ...mapGetters({
      goodItems: 'cart/goodItems',
    }),
    showFlag() {
      if (this.$store.state.logininfo.token == null || this.$store.state.logininfo.token == "") {
        return false
      } else {
        return true
      }
    }
  },
  methods: {
    async autoAddress() {
      this.address = await autoAddress(this.postcode);
    },
    fixNumber(price) {
      return fixPrice(price)
    },
    goToPayConfirm() {
      let errorFlag = false;

      if (this.mailaddress == null) {
        this.mailaddressErrorMsg = "メールアドレスを入力してください。"
        errorFlag = true;
      }
      if (!checkMailAddress(this.mailaddress)) {
        this.mailaddressErrorMsg = "メールアドレスを入力してください。"
        errorFlag = true;
      }
      if (this.firstname == null || this.firstname == "") {
        this.firstnameErrorMsg = "姓を入力してください。"
        errorFlag = true;
      }
      if (this.lastname == null || this.lastname == "") {
        this.lastnameErrorMsg = "名を入力してください。"
        errorFlag = true;
      }
      if (this.firstnamekana == null || this.firstnamekana == "") {
        this.firstnamekanaErrorMsg = "セイを入力してください。"
        errorFlag = true;
      }
      if (this.lastnamekana == null || this.lastnamekana == "") {
        this.lastnamekanaErrorMsg = "メイを入力してください。"
        errorFlag = true;
      }
      if (this.postcode == null || this.postcode == "") {
        this.postCodeErrorMsg = "郵便番号を入力してください。"
        errorFlag = true;
      }
      if (!checkPostCode(this.postcode)) {
        this.postCodeErrorMsg = "郵便番号を入力してください。"
        errorFlag = true;

      }
      // if (!checkPostCode(this.postcode)) {
      //   this.postCodeErrorMsg = "郵便番号は７桁数字を入力してください。"
      //   errorFlag = true;
      //
      // }
      if (this.address == null || this.address == "") {
        this.addressErrorMsg = "住所を入力してください。"
        errorFlag = true;
      }
      if (this.phone == null) {
        this.phoneErrorMsg = "電話番号を入力してください。"
        errorFlag = true;
      }
      if (!checkPhoneNumber(this.phone)) {
        this.phoneErrorMsg = "電話番号を入力してください。"
        errorFlag = true;
      }
      // if (!checkPhoneNumber(this.phone)) {
      //   this.phoneErrorMsg = "電話番号は１０桁あるいは１１桁数字だけを入力してください。"
      //   errorFlag = true;
      // }

      if (Number(this.allPrice().replaceAll(",", "")) > 300000 && this.radios == "0") {
        // alert("30万円以上の場合は代引き選択できない。\nお手続ですが、別々注文してお願いします");
        return;
      }

      if (errorFlag) {
        window.scrollTo(0, 0);
        return;
      }

      let orderDetail = {
        firstname: this.firstname,
        lastname: this.lastname,
        firstnamekana: this.firstnamekana,
        lastnamekana: this.lastnamekana,
        phone: this.phone,
        mailaddress: this.mailaddress,
        postcode: this.postcode,
        address: this.address + this.address1,
        note: this.note
      }
      this.$store.dispatch('orderinfo/setOrderDetail', {orderDetail: orderDetail})
      this.$store.dispatch('orderinfo/setPayMethod', {payMethod: this.radios})
      if (this.$route.path != '/payConfirm') {
        this.$router.push({
          name: "PayConfirm"
        });
      }
    },
    allPrice() {
      if (this.goodItems == null || this.goodItems.length == 0) {
        return 0
      } else {
        let allCount = 0;
        for (let i = 0; i < this.goodItems.length; i++) {
          allCount = allCount + (this.goodItems[i].number * this.goodItems[i].detail.price)
        }
        return fixPrice(allCount);
      }
    }

  }
};
</script>
<style scoped>
.infoMsg {
  margin-bottom: 2px;
}

.titleBody {
  font-size: 24px;
}

.required{
  font-size: 16px !important;
}

.required::after {
  content: "*";
  color: red;
  position: relative;
  left: 0;
  font-size: 20px !important;
}

.contentBody {
  font-size: 15px;
  margin-bottom: 5px !important;
}
</style>
