import Vue from 'vue'
import VueRouter from 'vue-router'
import RegistUser from '../views/RegistUser'
import Home from '../views/Home'
import MyPage from '../views/MyPage'
import CartPage from '../views/CartPage'
import SendPayInfo from '../views/SendPayInfo'
import GoodInfo from '../views/GoodInfo'
import Proxy from '../views/Proxy'
import UpdateUser from '../views/UpdateUser'
import InfoList from '../views/InfoList'
import InfoDetail from '../views/InfoDetail'
import GoodSearch from '../views/GoodSearch'
import GoodDetail from '../views/GoodDetail'
import Pay from '../views/Pay'
import PayConfirm from '../views/PayConfirm'
import Member from "@/views/Member.vue";
import PhoneHome from "@/views/PhoneHome.vue";
import PhoneSendPayInfo from "@/views/PhoneSendPayInfo.vue";
import PhoneProxy from "@/views/PhoneProxy.vue";
import PhoneGoodInfo from "@/views/PhoneGoodInfo.vue";
import PhoneGoodDetail from "@/views/PhoneGoodDetail.vue";
import PhoneGoodSearch from "@/views/PhoneGoodSearch.vue";
import PhoneInfoDetail from "@/views/PhoneInfoDetail.vue";
import PhoneInfoList from "@/views/PhoneInfoList.vue";
import PhoneRegistUser from "@/views/PhoneRegistUser.vue";
import PhoneMyPage from "@/views/PhoneMyPage.vue";
import PhoneUpdateUser from "@/views/PhoneUpdateUser.vue";
import PhoneCartPage from "@/views/PhoneCartPage.vue";
import PhonePay from "@/views/PhonePay.vue";
import PhonePayConfirm from "@/views/PhonePayConfirm.vue";
import PhoneMember from "@/views/PhoneMember.vue";

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/phone',
        name: 'PhoneHome',
        component: PhoneHome
    },
    {
        path: '/registUer',
        name: 'RegistUser',
        component: RegistUser
    },
    {
        path: '/phone/registUer',
        name: 'PhoneRegistUser',
        component: PhoneRegistUser
    },
    {
        path: '/myPage',
        name: 'MyPage',
        component: MyPage
    },
    {
        path: '/phone/myPage',
        name: 'PhoneMyPage',
        component: PhoneMyPage
    },
    {
        path: '/cart',
        name: 'CartPage',
        component: CartPage
    },
    {
        path: '/phone/cart',
        name: 'PhoneCartPage',
        component: PhoneCartPage
    },
    {
        path: '/sendAndPayInfo',
        name: 'SendPayInfo',
        component: SendPayInfo
    },
    {
        path: '/phone/sendAndPayInfo',
        name: 'PhoneSendPayInfo',
        component: PhoneSendPayInfo
    },
    {
        path: '/goodInfo',
        name: 'GoodInfo',
        component: GoodInfo
    },
    {
        path: '/phone/goodInfo',
        name: 'PhoneGoodInfo',
        component: PhoneGoodInfo
    },
    {
        path: '/proxy',
        name: 'Proxy',
        component: Proxy
    },
    {
        path: '/phone/proxy',
        name: 'PhoneProxy',
        component: PhoneProxy
    },
    {
        path: '/updateUser',
        name: 'UpdateUser',
        component: UpdateUser
    },
    {
        path: '/phone/updateUser',
        name: 'PhoneUpdateUser',
        component: PhoneUpdateUser
    },
    {
        path: '/infolist',
        name: 'InfoList',
        component: InfoList
    },
    {
        path: '/phone/infolist',
        name: 'PhoneInfoList',
        component: PhoneInfoList
    },
    {
        path: '/infoDetail/:infoId',
        name: 'InfoDetail',
        component: InfoDetail
    },
    {
        path: '/phone/infoDetail/:infoId',
        name: 'PhoneInfoDetail',
        component: PhoneInfoDetail
    },
    {
        path: '/goodSearch',
        name: 'GoodSearch',
        component: GoodSearch
    },
    {
        path: '/phone/goodSearch',
        name: 'PhoneGoodSearch',
        component: PhoneGoodSearch
    },
    {
        path: '/goodDetail/:goodId',
        name: 'GoodDetail',
        component: GoodDetail
    },
    {
        path: '/phone/goodDetail/:goodId',
        name: 'PhoneGoodDetail',
        component: PhoneGoodDetail
    },
    {
        path: '/pay',
        name: 'Pay',
        component: Pay
    },
    {
        path: '/phone/pay',
        name: 'PhonePay',
        component: PhonePay
    },
    {
        path: '/payConfirm',
        name: 'PayConfirm',
        component: PayConfirm
    },
    {
        path: '/phone/payConfirm',
        name: 'PhonePayConfirm',
        component: PhonePayConfirm
    },
    {
        path: '/member',
        name: 'Member',
        component: Member
    },
    {
        path: '/phone/member',
        name: 'PhoneMember',
        component: PhoneMember
    }
]

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {
    document.body.scrollTop = 0
    // firefox
    document.documentElement.scrollTop = 0

    next();
})

export default router
