<template>
  <v-card style="margin-top: 30px;margin-bottom: 30px">
    <v-card-title style="background-color: #F0F2F2!important;padding: 8px">
      <v-row>
        <v-col cols="2">
          <p class="title">注文番号<br>{{ this.orderDetail.orderid }}</p>
        </v-col>
        <v-col cols="3">
          <p class="title">注文日<br>{{ this.orderDetail.createdate }}</p>
        </v-col>
        <v-col cols="2">
          <p class="title">合計<br>{{ fixPrice(this.orderDetail.price) }}円</p>
        </v-col>
        <v-col cols="5">
          <p class="title">お届け先<br>{{ this.orderDetail.address }}</p>
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text style="padding: 30px !important;">
      <v-row align="center">
        <v-col cols="8">
          <v-row v-for="(goodDetail,index) in this.goodList" :key="index">
            <v-col cols="3">
              <img :src="urlImgHead+goodDetail.detail.imgurl" width="90px" height="67.5px"/>
            </v-col>
            <v-col cols="9">
              <p class="goodName">{{ goodDetail.detail.goodname }}</p>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="4">
          <p>配送業者：{{ postMethodString(this.orderDetail.postmethod) }}</p>
          <p>追跡番号：{{ this.orderDetail.contactnumber }}</p>
          <p>注文ステータス：<v-chip>{{ this.showStatus(Number(this.orderDetail.status)) }}</v-chip></p>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { postMethodString,fixPrice } from "@/utils/common"
import { urlImgHead } from "@/domain/RestClient"

export default {
  name: "HistoryDetail",
  props: {
    orderDetail: Object,
  },
  data(){
    return{
      urlImgHead
    }
  },
  computed: {
    goodList() {
      return JSON.parse(this.orderDetail.goodlistjson);
    },

  },
  methods: {
    postMethodString,
    fixPrice,
    showStatus(statusCode) {
      if (statusCode == 0) {
        return "  未入金"
      } else if (statusCode == 1) {
        return "未出荷"
      } else if (statusCode == 2) {
        return "出荷済"
      } else {
        return "キャンセル済"
      }
    }
  }
}
</script>

<style scoped>
.title {
  font-size: 14px !important;
  margin-bottom: 0px;
}

.goodName {
  font-size: 16px !important;
}
</style>
