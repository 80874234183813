<template>
  <v-container fluid>
    <v-row justify="center" >
      <v-container style="max-width: 1280px">
        <v-row cols="12" justify="center">
          <v-col cols="8">
            <v-text-field
                height="40px"
                dense
                solo
                label="キーワード・JANコード"
                clearable
                hide-details
                v-model="searchCondition"
            ></v-text-field>
          </v-col>
          <v-col cols="4">
            <v-btn
                height="40px"
                width="108px"
                color="#febd69"
                dark
                @click="goodSearch"
            >
              <v-icon style="color: #000000 !important;">
                mdi-magnify
              </v-icon>
              <div style="color: black;font-weight: bold;">検索</div>
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-row>
    <v-row justify="center" style="margin-top: 30px">
      <v-container style="max-width: 1280px">
        <v-row cols="12">
          <v-col cols="12" class="pa-0">
            <div class="float-left" v-show="showFlag" style="position: absolute">
              <SubSideCategoryMenu v-on:subCategorgShowStatus="subCategorgShowStatus"></SubSideCategoryMenu>
            </div>
            <v-row>
              <v-col>
                <v-row dense>
                  <v-col cols="12" class="mt-0 ml-3 pt-0"><p class="titleBody">
                    <b>検索条件:{{
                        this.condition.subCategoryName == null ? this.condition.keyword : this.condition.subCategoryName
                      }}</b>
                  </p></v-col>
                  <v-col cols="12" align="center" class="ml-3">
                    <v-row justify="start">
                      <v-col cols="5">
                        <v-select
                            dense
                            v-model="selectedOption"
                            :items="options"
                            item-text="label"
                            label="並び替え"
                            outlined
                            @change="onSelectChange"
                        ></v-select>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
                <v-row class="ml-3">
                  <p class="contentBody">検索結果：{{ showData == null ? 0 : showData.length }}件</p>
                </v-row>
                <v-row class="pa-0 ma-0" v-if="showData != null">
                  <v-col cols="6" class="pa-0 ma-0" v-for="goodDetail in showData" :key="goodDetail.goodid">
                    <PhoneGoodShow :goodDetail="goodDetail"></PhoneGoodShow>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-row>
  </v-container>
</template>

<script>
import SubSideCategoryMenu from '../components/SubSideCategoryMenu'
import 'vue-lunar-calendar-pro/dist/calendar.css'
import PhoneGoodShow from '../components/CommonUtils/PhoneGoodShow'
import {mapGetters} from 'vuex'
import {getGoodList} from "@/domain/api/good";


export default {
  name: 'PhoneGoodSearch',
  components: {
    SubSideCategoryMenu,
    PhoneGoodShow
  },
  data: () => ({
    showFlag1: false,
    showFlag2: false,
    searchCondition: null,
    showData: null,
    options: [
      {
        label: "新着順"
      },
      {
        label: "価格の低い順"
      },
      {
        label: "価格の高い順"
      }
    ],
    selectedOption: {
      label: "新着順"
    }
  }),
  async mounted() {
    if (this.condition.keyword != null) {
      this.showData = await getGoodList({searchFlag: "keyword", keyword: this.condition.keyword});
    } else {
      if (this.condition.subCategoryName === "すべて") {
        this.showData = await getGoodList({searchFlag: "categoryName", keyword: this.selectCategoryName});
      } else {
        this.showData = await getGoodList({searchFlag: "subCategoryName", keyword: this.condition.subCategoryName});
      }
    }
  },
  computed: {
    ...mapGetters({
      condition: 'searchcondition/condition',
      selectCategoryName: 'categoryInfo/selectCategoryName',
    }),
    showFlag: function () {
      if (this.showFlag1 || this.showFlag2) {
        return true
      } else {
        return false
      }
    }
  },
  methods: {
    onSelectChange() {
      if (this.selectedOption === "価格の低い順") {
        this.lowToHigh();
      } else if (this.selectedOption === "価格の高い順") {
        this.highTolow();
      } else {
        this.sortByDate();
      }
    },
    sortBy(filed, rev, primer) {
      rev = (rev) ? -1 : 1;
      return function (a, b) {
        a = a[filed].toString().replaceAll('-','');
        b = b[filed].toString().replaceAll('-','');
        if (typeof (primer) != 'undefined') {
          a = primer(a);
          b = primer(b);
        }
        if (a < b) {
          return rev * -1;
        }
        if (a > b) {
          return rev * 1;
        }
        return 1;
      }
    },
    lowToHigh() {
      this.showData = this.showData.sort(this.sortBy('price', false, parseInt));
    },
    highTolow(){
      this.showData = this.showData.sort(this.sortBy('price', true, parseInt));
    },
    sortByDate(){
      this.showData = this.showData.sort(this.sortBy('createdate', true, parseInt));
    },
    subCategorgShowFlag(flag) {
      this.showFlag1 = flag;
    },
    subCategorgShowStatus(flag) {
      this.showFlag2 = flag
    },
    goToInfoList() {
      this.$router.push({
        name: "PhoneInfoList"
      });
    },
    async goodSearch() {
      this.$store.dispatch('searchcondition/setCondition', {
        condition: {
          subCategoryName: null,
          keyword: this.searchCondition
        }
      });
      this.showData = await getGoodList({searchFlag: "keyword", keyword: this.searchCondition});
    },
  }
};
</script>
<style>
.titleBody {
  font-size: 20px !important;
}

.contentBody {
  font-size: 15px;
  margin-bottom: 5px !important;
}
</style>
