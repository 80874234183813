<template>
  <v-container class="pa-0 ma-0">
    <p style="margin-bottom:0px!important;">
      <a class="categoryMenuBtn" style="color: white" @mouseenter="showMenu" @mouseleave="hiddenMenu">カテゴリから選ぶ</a>
    </p>
    <div ref="sideMenuShow" class="side-menu" @mouseenter="showMenu" @mouseleave="hiddenMenu">
      <div
          class="side-menu-content">
        <div ref="sideMenuShow1" class="side-sub-menu" @mouseleave="hiddenMenuSecond" @mouseenter="showMenuSecond">
          <div class="mouseEventChange"
               v-for="(item, i) in itemsSub" :key="i">
            <p @click="goodSearch(item)">{{ item }}</p>
          </div>
        </div>
        <div class="mouseEventChange"
             v-for="(item, i) in items" :key="i">
          <p @mouseleave="hiddenMenuSecond" @mouseenter="showMenuSecondSP(item)">{{
              item
            }}</p>
        </div>
      </div>
    </div>
  </v-container>
</template>

<script>
import {mapGetters} from 'vuex'
import {getCategoryList} from "@/domain/api/category";

export default {
  name: "SideCategoryMenu",
  data: () => ({
    selectedItem: null,
    items: []
  }),
  mounted: async function () {
    this.items = await getCategoryList();
    this.$refs.sideMenuShow.style.visibility = "hidden";
    this.$refs.sideMenuShow1.style.visibility = "hidden";
  },
  computed: {
    ...mapGetters({
      selectCategoryName: 'categoryInfo/selectCategoryName',
      categoryList: 'categoryInfo/categoryList'
    }),
    itemsSub: function () {
      let result = [];
      if (this.categoryList != null) {
        result.push("すべて")
        for (let i = 0; i < this.categoryList.length; i++) {
          if (this.categoryList[i].categoryName == this.selectCategoryName) {
            if (!result.includes(this.categoryList[i].categorySubName)) {
              result.push(this.categoryList[i].categorySubName)
            }
          }
        }
        return result
      } else {
        return [""]
      }
    }
  },

  methods: {
    showMenuSecondSP(item) {
      this.$store.dispatch('categoryInfo/setSelectCategoryName', {selectCategoryName: item});
      this.$refs.sideMenuShow.style.visibility = "visible";
      this.$refs.sideMenuShow1.style.visibility = "visible";
    },
    showMenu() {
      this.$refs.sideMenuShow.style.visibility = "visible";
    },
    hiddenMenu() {
      this.$refs.sideMenuShow.style.visibility = "hidden";
    },
    showMenuSecond() {
      this.$refs.sideMenuShow.style.visibility = "visible";
      this.$refs.sideMenuShow1.style.visibility = "visible";
    },
    hiddenMenuSecond() {
      this.$refs.sideMenuShow.style.visibility = "hidden";
      this.$refs.sideMenuShow1.style.visibility = "hidden";
    },
    goodSearch(subCategoryName) {
      this.$store.dispatch('searchcondition/setCondition', {
        condition: {
          subCategoryName: subCategoryName,
          keyword: null
        }
      });
      if (this.$route.path == "/goodSearch") {
        window.location.reload();
      } else {
        this.$router.push({
          name: "GoodSearch"
        });
      }
    }
  }
}
</script>

<style scoped>
.side-menu {
  position: absolute;
  z-index: 99999;
  visibility: hidden;
}

.side-menu-content {
  width: 188px;
  min-height: 200px;
  background-color: white;
  border-style: solid;
  border-width: 2px;
  border-color: #000;
  border-radius: 5px;
  box-shadow: 2px 2px 4px 0 rgb(128, 128, 128);
}

.side-sub-menu {
  margin-top: 0px;
  padding-top: 0px;
  min-height: 200px;
  width: 330px;
  position: absolute;
  margin-left: 99%;
  z-index: 88;
  background-color: white;
  border-style: solid;
  border-width: 2px;
  border-color: #000;
  border-radius: 5px;
  box-shadow: 2px 2px 4px 0 rgb(128, 128, 128);
  visibility: hidden;
}

.mouseEventChange {
  height: 30px;
  vertical-align: center;
  text-align: left;
  padding-left: 20px;
  line-height: 30px;
  font-size: 16px;
  color: #356bb3;
}

.mouseEventChange:hover {
  color: red;
  text-decoration: underline;
  cursor: pointer;
}
</style>
