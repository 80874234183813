import {get} from "@/domain/RestClient";
import store from "@/store/index"

export const getCategoryList = async () => {
    try {
        const response = await get("/categorylist");
        store.dispatch('categoryInfo/setCategoryList', {categoryList: response.data.result});
        let result = await getCommonBaseList();
        let categoryNameList = [];
        for (let i = 0; i < result.length; i++) {
            categoryNameList.push(result[i].stringname)
        }
        return categoryNameList;
    } catch (error) {
        console.log(error)
    }
};

export const getCommonBaseList = async () => {
    try {
        const response = await get("public/basecategory");
        return response.data.result;
    } catch (error) {
        console.log(error);
    }
};
