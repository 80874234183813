const namespaced = true;

const defaultData = {
    goodItems: []
};

const getState = () => ({
    ...defaultData
});

export default {
    namespaced,
    state: getState(),
    mutations: {
        setGoodItems(state, payload) {
            state.goodItems = payload.goodItems;
        },
        init(state) {
            Object.assign(state, defaultData);
        }
    },
    getters: {
        goodItems: state => state.goodItems
    },
    actions: {
        setGoodItems({commit}, {goodItems}) {
            commit("setGoodItems", {goodItems});
        },
        refresh({commit}) {
            commit("init");
        }
    }
}
