<template>
  <v-container fluid>
    <v-row>
      <v-container style="max-width: 1280px" v-if="goodDetail != null">
        <v-row cols="12" justify="start">
          <p style="font-size: 16px;margin-left: 10px"><a href="../../phone">ホーム</a> ＞ <a
              style="text-decoration: underline;"
              @click="searchPage">{{ goodDetail.categorysubname }}</a>
            > {{ goodDetail.maker }}</p>
        </v-row>
        <v-row cols="12" justify="center">
          <v-col cols="12">
            <img height="250px" width="100%"
                 :src="urlImgHead+goodDetail.imgurl"/>
          </v-col>
          <v-col cols="12">
            <v-row>
              <p style="font-size: 20px;margin-left: 10px"><b>{{ goodDetail.goodname }}</b></p>
            </v-row>
            <v-row>
              <p style="font-size: 16px;margin-left: 10px">JANコード:{{ goodDetail.jancode }}</p>
            </v-row>
            <v-row>
              <p style="font-size: 16px;margin-left: 10px">価格：<b style="color: red;font-size: 20px;">￥{{
                  showprice
                }}</b><span
                  style="color: black;font-size: 13px">（税込）</span></p>
            </v-row>
            <v-row>
              <p style="font-size: 16px;margin-left: 10px">在庫状況：{{ showStock }}</p>
            </v-row>
            <v-row v-show="hideFlag">
              <v-col cols="5">
                <v-select
                    style="max-width: 120px;"
                    outlined
                    dense
                    :items="buyCountList"
                    v-model="buyCount"
                    label="購入数"
                ></v-select>
              </v-col>
              <v-col cols="5">
                <v-btn
                    style="color:whitesmoke"
                    color="#FFD814"
                    :disabled="stockFlag"
                    @click="addGoodCart()"
                >
                  <div style="color: black">カートに入れる</div>
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12">
            <p class="contentBody">商品説明：</p>
            <p class="contentBody">
              {{ goodDetail.goodinfo }}</p>
          </v-col>
        </v-row>
        <v-row cols="12">
          <v-divider></v-divider>
        </v-row>
        <v-row cols="12" dense style="margin-top: 30px">
          <v-col cols="12">
            <p class="contentBody">■送料無料（沖縄・一部離島を除く）</p>
            <p class="contentBody">■15時までのご注文につきましては、当日出荷致します。</p>
            <p class="contentBody">
              ■ご注文確認メールやお問い合わせメールの返信が届かない場合は「迷惑メールフォルダ」の確認をお願い致します。</p>
            <p class="contentBody">■何かご不明な点などがございましたら、お気軽にお問い合わせください。</p>
            <p class="contentBody">お問い合せ先：sale@akiba109.com</p>
          </v-col>
        </v-row>
      </v-container>
    </v-row>
  </v-container>
</template>

<script>
import {getGoodDetail} from "@/domain/api/good";
import {fixPrice} from "@/utils/common";
import {mapGetters} from 'vuex'
import {urlImgHead} from "@/domain/RestClient"

export default {
  name: 'GoodDetail',
  data: () => ({
    showFlag1: false,
    showFlag2: false,
    goodDetail: null,
    buyCount: 1,
    urlImgHead,
    buyCountList: [],
    hideFlag: true
  }),
  async mounted() {
    const goodid = this.$route.path.split("/")[3];
    this.goodDetail = await getGoodDetail(goodid);
    for (let i = 1; i <= this.goodDetail.stock; i++) {
      this.buyCountList.push(i);
    }
    if (this.goodDetail.stock === 0) {
      this.hideFlag = false
    }
  },
  methods: {
    async searchPage() {
      await this.$store.dispatch('searchcondition/setCondition', {
        condition: {
          subCategoryName: this.goodDetail.categorysubname,
          keyword: null
        }
      });
      if (this.$route.path != "/phone/goodSearch") {
        await this.$router.push({
          name: "PhoneGoodSearch"
        });
      }
    },
    addGoodCart() {
      for (let i = 0; i < this.goodItems.length; i++) {
        if (this.goodItems[i].goodid == this.goodDetail.goodid) {
          this.$store.state.cart.goodItems.splice(i, 1);
        }
      }
      this.$store.state.cart.goodItems.push({
        goodid: this.goodDetail.goodid,
        number: this.buyCount,
        detail: this.goodDetail
      });
      this.$store.dispatch('cart/setGoodItems', {goodItems: this.$store.state.cart.goodItems})
      this.$router.push({name: 'PhoneCartPage'});
    }
  },
  computed: {
    ...mapGetters({
      goodItems: 'cart/goodItems',
    }),
    stockFlag() {
      if (this.goodDetail == null || this.goodDetail.stock <= 0 || this.buyCount <= 0) {
        return true
      } else {
        return false
      }
    },
    showprice() {
      if (this.goodDetail == null) {
        return 0
      } else {
        return fixPrice(this.goodDetail.price)
      }
    },
    showStock() {
      if (this.goodDetail == null || this.goodDetail.stock <= 0) {
        return "入荷待ち"
      } else if (this.goodDetail.stock <= 3) {
        return "残りわずか";
      } else {
        return "在庫あり";
      }
    }
  }
};
</script>
<style>
.contentBody {
  font-size: 16px !important;
  margin-bottom: 0px;
}

</style>
