<template>
  <v-container fluid>
    <v-row justify="center">
      <v-container style="max-width: 1280px">
        <v-row cols="12">
          <v-col cols="12" class="pa-0 ">
            <p style="font-size: 16px"><a href="./">ホーム</a> ＞ お知らせ一覧</p>
          </v-col>
          <v-col cols="2"></v-col>
          <v-col cols="10" class="pa-0">
            <v-row  style="margin-top: 25px">
              <v-col>
                <v-row class="pl-10">
                  <InfoShotShow v-for="infoDetail in infoList" :key="infoDetail.infoid" :infoDetail="infoDetail"></InfoShotShow>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-row>
  </v-container>
</template>

<script>
import 'vue-lunar-calendar-pro/dist/calendar.css'
import InfoShotShow from '../components/CommonUtils/InfoShotShow'
import {getInfoListAll} from "@/domain/api/info";

export default {
  name: 'InfoList',
  components: {
    InfoShotShow
  },
  async mounted() {
    this.infoList = await getInfoListAll();
  },
  data: () => ({
    infoList: []
  }),
};
</script>
<style>
.titleBody{
  font-size: 24px;
}
.contentBody{
  font-size: 15px;
  margin-bottom: 5px !important;
}
</style>
