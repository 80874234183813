<template>
  <v-container fluid>
    <v-row justify="center">
      <v-container style="max-width: 1280px">
        <v-card>
          <v-card-text>
            <v-row>
              <v-col cols="12" style="border-right: 1px solid #dcdcdc;" align="center">
                <p class="bodyContent"><b>会員登録がお済みの方</b></p>
                <v-text-field
                    style="width: 80% !important;"
                    outlined
                    dense
                    label="メールアドレス"
                    :error-messages="mailErrorMsg"
                    class="mt-2"
                    v-model="loginId"
                ></v-text-field>
                <v-text-field
                    :type="show ? 'text' : 'password'"
                    :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                    @click:append="show = !show"
                    style="width: 80% !important;"
                    outlined
                    dense
                    label="パスワード"
                    :error-messages="passwordErrorMsg"
                    v-model="password"
                ></v-text-field>
                <v-btn
                    color="green"
                    dark
                    class="btn_menu"
                    width="80%"
                    @click="loginPro"
                >
                  ログイン
                </v-btn>
              </v-col>
            <v-col cols="12">
              <v-divider></v-divider>
            </v-col>
              <v-col cols="12" align="center">
                <p class="bodyContent"><b>新規会員登録</b></p>
                <p class="bodyContent">新規会員を登録する方は、下記より情報を入力して会員登録してださい。</p>
                <v-btn
                    color="blue"
                    dark
                    class="btn_menu"
                    width="80%"
                    @click="goToRegist"
                >
                  新規会員登録をする
                </v-btn>
                <v-divider style="margin-top: 20px"></v-divider>
                <p class="bodyContent" style="margin-top: 20px"><b>ゲスト購入</b></p>
                <p class="bodyContent">会員登録をせずに購入手続きをされたい方は、下記よりお進みください。</p>
                <p class="bodyContent" style="color: red">⚠️ゲスト購入時には購入履歴が残りません。<br>
                マイページでの注文履歴確認の利用をご希望の場合は、会員登録をお済ませの上ご注文ください。
                </p>
                <v-btn
                    color="orange"
                    dark
                    class="btn_menu"
                    width="80%"
                    @click="goToPay"
                >
                  ゲスト購入
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-container>
    </v-row>
  </v-container>
</template>

<script>
import 'vue-lunar-calendar-pro/dist/calendar.css'
import {login} from "@/domain/api/user";
import {checkMailAddress} from "@/utils/common";

export default {
  name: 'PhoneMember',
  data() {
    return {
      show: false,
      loginId: null,
      password: null,
      mailErrorMsg: "",
      passwordErrorMsg: ""
    }
  },
  methods: {
    goToRegist() {
      if (this.$route.path != '/phone/registUer') {
        this.$router.push({
          name: "PhoneRegistUser"
        });
      }
    },
    goToPay() {
      if (this.$route.path != '/phone/pay') {
        this.$router.push({
          name: "PhonePay"
        });
      }
    },
    async loginPro() {
      let errorFlag = false;
      if (this.loginId == "" || this.loginId == null) {
        this.mailErrorMsg = "メールアドレスを入力してください。"
        errorFlag = true;
      }
      if (!checkMailAddress(this.loginId)) {
        this.mailErrorMsg = "メールアドレスを入力してください。"
        errorFlag = true;
      }
      if (this.password == "" || this.password == null) {
        this.passwordErrorMsg = "パスワードを入力してください。"
        errorFlag = true;
      }

      if (errorFlag) {
        return;
      }

      try {
        let result = await login({
          loginId: this.loginId,
          password: this.password
        });
        this.dialog = false;
        this.$store.dispatch('logininfo/setLoginId', {
          loginId: this.loginId,
          token: result.data.token,
          userId: result.data.userId
        });
        this.loginId = "";
        this.password = "";
      } catch (e) {
        // alert("お客さま登録の確認ができませんでした。\nメールアドレス、パスワードを確認して下さい。");
        return
      }
      if (this.$store.state.logininfo.token == null || this.$store.state.logininfo.token == "") {
        return
      } else {
        if (this.$route.path != '/phone/pay') {
          this.$router.push({
            name: "PhonePay"
          });
        }
      }
    }
  }
};
</script>
<style>
.titleBody {
  font-size: 24px;
}

.contentBody {
  font-size: 15px;
  margin-bottom: 5px !important;
}

.bodyContent {
  font-size: 16px;
}
</style>
