<template>
  <v-container fluid>
    <v-row justify="center">
      <v-container style="max-width: 1280px">
        <v-row cols="12">
          <v-col cols="12" class="pa-0 ">
            <p style="font-size: 16px"><a href="./">ホーム</a> ＞ 配送・決済について</p>
          </v-col>
          <v-col cols="2"></v-col>
          <v-col cols="10" class="pa-0">
            <v-row  style="margin-top: 25px">
              <v-col>
                <v-row class="pl-10">
                  <p class="titleBody"><b>配送・送料について</b></p>
                </v-row>
                <v-row class="pl-10">
                  <p class="contentBody"><b>ヤマト運輸</b></p>
                </v-row>
                <v-row class="pl-10">
                  <p class="contentBody">●送料無料（沖縄・一部離島を除く）</p>
                </v-row>
                <v-row class="pl-10">
                  <p class="contentBody">●代引手数料について以下</p>
                </v-row>
                <v-row class="pl-10">
                  <p class="contentBody">商品代金 1 ～ 99,999円 代引手数料 550円</p>
                </v-row>
                <v-row class="pl-10">
                  <p class="contentBody">商品代金 100,000 ～ 300,000円 代引手数料 1,100円</p>
                </v-row>
                <v-row class="pl-10">
                  <v-divider></v-divider>
                </v-row>
                <v-row class="pl-10 mt-10">
                  <p class="titleBody"><b>支払い方法について</b></p>
                </v-row>
                <v-row class="pl-10">
                  <p class="contentBody"><b>銀行振込</b></p>
                </v-row>
                <v-row class="pl-10">
                  <p class="contentBody">三菱UFJ銀行 千住中央支店</p>
                </v-row>
                <v-row class="pl-10">
                  <p class="contentBody"><b>商品代引</b></p>
                </v-row>
                <v-row class="pl-10">
                  <p class="contentBody">ヤマト運輸コレクト</p>
                </v-row>
                <v-row class="pl-10">
                  <v-divider></v-divider>
                </v-row>
                <v-row class="pl-10 mt-10">
                  <p class="titleBody"><b>返品について</b></p>
                </v-row>
                <v-row class="pl-10">
                  <p class="contentBody"><b>返品期限</b></p>
                </v-row>
                <v-row class="pl-10">
                  <p class="contentBody">初期不良については商品到着日から3日間以内となります。</p>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-row>
  </v-container>
</template>

<script>
import 'vue-lunar-calendar-pro/dist/calendar.css'

export default {
  name: 'SendPayInfo',
};
</script>
<style>
.titleBody{
  font-size: 24px;
}
.contentBody{
  font-size: 15px;
}
</style>
