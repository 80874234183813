<template>
  <v-container style="max-width: 1280px;">
    <v-dialog
        v-model="dialog"
        width="500"
    >
      <v-card shaped elevation="2">
        <v-card-title class="text-h5 lighten-2" style="background-color:#57acad">
          ログイン
        </v-card-title>
        <v-card-text style="margin-top: 30px;text-align: center;" class="pb-0">
          <v-text-field
              style="width: 400px !important;"
              outlined
              dense
              label="メールアドレス"
              class="mt-2"
              v-model="loginId"
              :error-messages="mailErrorMsg"
          ></v-text-field>
          <v-text-field
              :type="show ? 'text' : 'password'"
              :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="show = !show"
              style="width: 400px !important;"
              outlined
              dense
              label="パスワード"
              v-model="password"
              :error-messages="passwordErrorMsg"
          ></v-text-field>
        </v-card-text>
        <v-card-actions style="text-align: center">
          <v-spacer></v-spacer>
          <v-btn
              color="primary"
              text
              outlined
              @click="login"
              width="120px"
          >
            ログイン
          </v-btn>
          <v-btn
              outlined
              color="red"
              text
              width="120px"
              @click="dialog = false"
          >
            閉じる
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-row style="max-height: 80px" cols="12" justify="center">
      <v-col class="pa-0 ma-0" cols="6">
        <div style="margin-left: 16px">
          <img style="cursor: pointer" class="logo_size" src="../assets/logo.png" @click="backToHome">
        </div>
      </v-col>
      <v-col cols="6" align="center" style="display: flex;align-items: center;justify-content: end">
        <v-btn
            v-if="!loginStatus"
            rounded
            color="primary"
            dark
            @click="goToRegistPage"
            class="btn_menu"
        >
          <v-icon gray dark>
            mdi-account-plus
          </v-icon>
          会員登録
        </v-btn>
        <v-btn
            v-if="!loginStatus"
            rounded
            color="green"
            dark
            class="btn_menu"
            @click="openLoginDialog"
        >
          <v-icon gray dark>
            mdi-account-check
          </v-icon>
          ログイン
        </v-btn>
        <v-btn
            v-else
            rounded
            color="#ff00ff"
            dark
            class="btn_menu"
            @click="logout"
        >
          <v-icon gray dark>
            mdi-login
          </v-icon>
          ログアウト
        </v-btn>
        <v-btn
            rounded
            color="orange"
            dark
            @click="goToMyPage"
            class="btn_menu"
        >
          <v-icon gray dark>
            mdi-account
          </v-icon>
          マイページ
        </v-btn>
        <v-btn
            rounded
            color="pink"
            dark
            @click="goToCart"
            class="btn_menu"
        >
          <v-icon gray dark>
            mdi-cart
          </v-icon>
          カート
          <span class="cart_count">{{ goodItems.length }}</span>
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {mapGetters} from 'vuex'
import {login} from "@/domain/api/user";
import {checkMailAddress} from "@/utils/common";

export default {
  name: "Menu",
  data() {
    return {
      dialog: false,
      loginId: null,
      password: null,
      show: false,
      mailErrorMsg: "",
      passwordErrorMsg: ""
    }
  },
  computed: {
    ...mapGetters({
      token: 'logininfo/token',
      goodItems: 'cart/goodItems'
    }),
    loginStatus() {
      if (this.token == "") {
        return false;
      } else {
        return true;
      }
    }
  },
  methods: {
    async login() {
      let errorFlag = false;
      if (this.loginId == "" || this.loginId == null) {
        this.mailErrorMsg = "メールアドレスを入力してください。"
        errorFlag = true;
      }
      if (!checkMailAddress(this.loginId)) {
        this.mailErrorMsg = "メールアドレスを入力してください。"
        errorFlag = true;
      }
      if (this.password == "" || this.password == null) {
        this.passwordErrorMsg = "パスワードを入力してください。"
        errorFlag = true;
      }

      if (errorFlag) {
        return;
      }
      try {
        let result = await login({
          loginId: this.loginId,
          password: this.password
        });
        this.dialog = false;
        this.$store.dispatch('logininfo/setLoginId', {
          loginId: this.loginId,
          token: result.data.token,
          userId: result.data.userId
        });
        this.loginId = "";
        this.password = "";
      } catch (e) {
        // alert("お客さま登録の確認ができませんでした。\nメールアドレス、パスワードを確認して下さい。");
      }
    },
    logout() {
      this.$store.dispatch('logininfo/refresh');
      this.$store.dispatch('orderinfo/refresh');
      if (this.$route.path != '/') {
        this.$router.push({
          name: "Home"
        });
      }
    },
    goToRegistPage() {
      if (this.$route.path != '/registUer') {
        this.$router.push({
          name: "RegistUser"
        });
      }
    },
    openLoginDialog() {
      this.dialog = true
    },
    goToMyPage() {
      if (this.loginStatus) {
        if (this.$route.path != '/myPage') {
          this.$router.push({
            name: "MyPage"
          });
        }
      } else {
        this.dialog = true;
      }

    },
    goToCart() {
      if (this.$route.path != '/cart') {
        this.$router.push({
          name: "CartPage"
        });
      }
    },
    backToHome() {
      if (this.$route.path != '/') {
        this.$router.push({
          name: "Home"
        });
      }
    }
  }
}
</script>

<style scoped>
.logo_size {
  height: 50px;
  width: 320px;
  margin-top: 10px;
}

.btn_menu {
  margin: 5px;
}

.cart_count {
  position: absolute;
  top: -10px;
  right: -10px;
  font-size: 12px;
  font-weight: bold;
  color: white;
  background-color: red;
  padding: 2px 5px;
  border-radius: 50%;
}
</style>
