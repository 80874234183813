<template>
  <v-container fluid>
    <v-row justify="center">
      <v-container style="max-width: 1280px">
        <v-row cols="12">
          <v-col cols="12" class="pa-0 ">
            <p style="font-size: 16px"><a href="./">ホーム</a> ＞ プライバシーポリシー</p>
          </v-col>
          <v-col cols="2"></v-col>
          <v-col cols="8" class="pa-0">
            <v-row  style="margin-top: 25px">
              <v-col>
                <v-row class="pl-10">
                  <p class="titleBody"><b>プライバシーポリシー</b></p>
                </v-row>
                <v-row class="pl-10">
                  <p class="contentBody"><b>1.個人情報の定義</b></p>
                </v-row>
                <v-row class="pl-10">
                  <p class="contentBody">「個人情報」とは、生存する個人に関する情報であって、当該情報に含まれる氏名、生年月日その他の記述等により特定の個人を識別することができるもの、及び他の情報と容易に照合することができ、それにより特定の個人を識別することができることとなるものをいいます。</p>
                </v-row>
                <v-row class="pl-10 mt-7">
                  <p class="contentBody"><b>2.個人情報の収集</b></p>
                </v-row>
                <v-row class="pl-10">
                  <p class="contentBody">当ショップでは商品のご購入、お問合せをされた際にお客様の個人情報を収集することがございます。収集するにあたっては利用目的を明記の上、適法かつ公正な手段によります。</p>
                </v-row>
                <v-row class="pl-10">
                  <p class="contentBody">当ショップで収集する個人情報は以下の通りです。</p>
                </v-row>
                <v-row class="pl-10 mt-5" >
                  <p class="contentBody">a)お名前、フリガナ</p>
                </v-row>
                <v-row class="pl-10">
                  <p class="contentBody">b)ご住所</p>
                </v-row>
                <v-row class="pl-10">
                  <p class="contentBody">c)お電話番号</p>
                </v-row>
                <v-row class="pl-10">
                  <p class="contentBody">d)メールアドレス</p>
                </v-row>
                <v-row class="pl-10">
                  <p class="contentBody">e)パスワード</p>
                </v-row>
                <v-row class="pl-10">
                  <p class="contentBody">f)配送先情報</p>
                </v-row>
                <v-row class="pl-10">
                  <p class="contentBody">g)当ショップとのお取引履歴及びその内容</p>
                </v-row>
                <v-row class="pl-10">
                  <p class="contentBody">h)上記を組み合わせることで特定の個人が識別できる情報</p>
                </v-row>
                <v-row class="pl-10 mt-7">
                  <p class="contentBody"><b>3.個人情報の利用</b></p>
                </v-row>
                <v-row class="pl-10">
                  <p class="contentBody">当ショップではお客様からお預かりした個人情報の利用目的は以下の通りです。</p>
                </v-row>
                <v-row class="pl-10 mt-7">
                  <p class="contentBody">a)ご注文の確認、照会</p>
                </v-row>
                <v-row class="pl-10">
                  <p class="contentBody">b)商品発送の確認、照会</p>
                </v-row>
                <v-row class="pl-10">
                  <p class="contentBody">c)お問合せの返信時</p>
                </v-row>
                <v-row class="pl-10 mt-7">
                  <p class="contentBody">当ショップでは、下記の場合を除いてはお客様の断りなく第三者に個人情報を開示・提供することはいたしません。</p>
                </v-row>
                <v-row class="pl-10 mt-7">
                  <p class="contentBody">a)法令に基づく場合、及び国の機関若しくは地方公共団体又はその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合</p>
                </v-row>
                <v-row class="pl-10">
                  <p class="contentBody">b)人の生命、身体又は財産の保護のために必要がある場合であって、本人の同意を得ることが困難である場合</p>
                </v-row>
                <v-row class="pl-10 mt-7">
                  <p class="contentBody"><b>4.個人情報の安全管理</b></p>
                </v-row>
                <v-row class="pl-10">
                  <p class="contentBody">お客様よりお預かりした個人情報の安全管理はサービス提供会社によって合理的、組織的、物理的、人的、技術的施策を講じるとともに、当ショップでは関連法令に準じた適切な取扱いを行うことで個人データへの不正な侵入、個人情報の紛失、改ざん、漏えい等の危険防止に努めます。</p>
                </v-row>
                <v-row class="pl-10 mt-7">
                  <p class="contentBody"><b>5.個人情報の訂正、削除</b></p>
                </v-row>
                <v-row class="pl-10">
                  <p class="contentBody">お客様からお預かりした個人情報の訂正・削除は下記の問合せ先よりお知らせ下さい。また、ユーザー登録された場合、当サイトのメニュー「マイページ」より個人情報の訂正が出来ます。</p>
                </v-row>
                <v-row class="pl-10 mt-7">
                  <p class="contentBody"><b>6.session(セッション)の使用について</b></p>
                </v-row>
                <v-row class="pl-10">
                  <p class="contentBody">当社は、お客様によりよいサービスを提供するため、session(セッション)を使用することがありますが、これにより個人を特定できる情報の収集を行えるものではなく、お客様のプライバシーを侵害することはございません。 また、session(セッション)の受け入れを希望されない場合は、ブラウザの設定で変更することができます。※session(セッション)とは、サーバーコンピュータからお客様のブラウザに送信され、お客様が使用しているコンピュータのハードディスクに蓄積される情報です。</p>
                </v-row>
                <v-row class="pl-10 mt-7">
                  <p class="contentBody"><b>7.お問合せ先</b></p>
                </v-row>
                <v-row class="pl-10">
                  <p class="contentBody">特定商取引法に基づく表記をご覧ください。</p>
                </v-row>
                <v-row class="pl-10 mt-7">
                  <p class="contentBody"><b>8.プライバシーポリシーの変更</b></p>
                </v-row>
                <v-row class="pl-10 mb-10">
                  <p class="contentBody">当ショップでは、収集する個人情報の変更、利用目的の変更、またはその他プライバシーポリシーの変更を行う際は、当ページへの変更をもって公表とさせていただきます。</p>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-row>
  </v-container>
</template>

<script>
import 'vue-lunar-calendar-pro/dist/calendar.css'

export default {
  name: 'Proxy',
};
</script>
<style>
.titleBody{
  font-size: 24px;
}
.contentBody{
  font-size: 15px;
  margin-bottom: 5px !important;
}
</style>
