<template>
  <v-container fluid>
    <v-row justify="center">
      <v-container style="max-width: 1280px">
        <v-row cols="12" justify="center">
          <v-col cols="2">
            <SideCategoryMenu></SideCategoryMenu>
          </v-col>
          <v-col cols="8">
            <v-text-field
                height="40px"
                dense
                solo
                label="キーワード・JANコード"
                clearable
                hide-details
                v-model="searchCondition"
                @keyup.enter="goodSearch"
            ></v-text-field>
          </v-col>
          <v-col cols="2">
            <v-btn
                height="40px"
                width="185px"
                color="#febd69"
                dark
                @click="goodSearch"
            >
              <v-icon style="color: #000000 !important;">
                mdi-magnify
              </v-icon>
              <div style="color: black;font-weight: bold;">検索</div>
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-row>
    <v-row justify="center">
      <v-carousel cycle interval="5000" style="height: 400px;width: 1280px">
        <v-carousel-item
            v-for="(item, i) in items"
            :key="i"
        >
          <v-img :src="require(`@/assets/${item.src}`)" height="400px" :contain="true"/>
        </v-carousel-item>
      </v-carousel>
    </v-row>
    <v-row justify="center" style="margin-top: 30px">
      <v-container style="max-width: 1280px">
        <v-row cols="12">
          <v-col cols="12" class="pa-0">
            <v-row>
              <v-col>
                <p class="pl-3" style="font-size: 20px"><b>新着商品</b></p>
              </v-col>
            </v-row>
            <v-row class="pa-0 ma-0">
              <v-col v-for="goodDetail in lastGoodList" :key="goodDetail.goodid" cols="3" class="pa-0 ma-0">
                <GoodShow :goodDetail="goodDetail"></GoodShow>
              </v-col>
            </v-row>
            <v-row style="margin-top: 30px">
              <v-divider></v-divider>
            </v-row>
            <v-row>
              <v-col>
                <p class="pl-3" style="font-size: 20px"><b>おすすめ商品</b></p>
              </v-col>
            </v-row>
            <v-row class="pa-0 ma-0">
              <v-col v-for="goodDetail1 in recommendList" :key="goodDetail1.goodid" cols="3" class="pa-0 ma-0">
                <GoodShow :goodDetail="goodDetail1"></GoodShow>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-row>
    <v-row justify="center" style="margin-top:50px">
      <v-container style="max-width: 1280px">
        <v-row v-if="showCalendarFlag">
          <v-col cols="2">
            <div class="table_header_calendar">{{ new Date().getFullYear() }}年{{ new Date().getMonth() + 1 }}月</div>
            <div class="table_header_info">
              <div class="table_header_info_item" style="background-color: pink;color: red">
                日
              </div>
              <div class="table_header_info_item">
                月
              </div>
              <div class="table_header_info_item">
                火
              </div>
              <div class="table_header_info_item">
                水
              </div>
              <div class="table_header_info_item">
                木
              </div>
              <div class="table_header_info_item">
                金
              </div>
              <div class="table_header_info_item">
                土
              </div>
            </div>
            <Calendar :show-lunar="false" :show-festival="false" :show-term="false" :highlighter-date="highlighter"
                      :show-title="false" max-date="2022-12-31" min-date="2022-12-01"
            ></Calendar>
            <div style="display: flex; align-items: center; margin-top: -35px;">
              <div style="width: 25px; height: 18px; background-color: pink;"></div>
              <div style="margin-left: 10px;font-size: 14px">休業日</div>
            </div>
          </v-col>
          <v-col cols="10">
            <p class="pl-3" style="font-size: 20px"><b>ショップからのお知らせ</b></p>
            <div v-for="info in infoList" :key="info.infoid">
              <MessageCutShow :infoDetail="info"></MessageCutShow>
              <v-row class="pl-3 mb-1">
                <v-divider></v-divider>
              </v-row>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-row>
  </v-container>
</template>

<script>
import SideCategoryMenu from '../components/SideCategoryMenu'
import GoodShow from '../components/CommonUtils/GoodShow'
import MessageCutShow from '../components/CommonUtils/MessageCutShow'
import 'vue-lunar-calendar-pro/dist/calendar.css'
import Calendar from 'vue-lunar-calendar-pro'
import {getLastInfoList} from "@/domain/api/info";
import {getHolidayList} from "@/domain/api/holiday";
import {getLastGoodList, getRecommendList} from "@/domain/api/good";

export default {
  name: 'Home',
  components: {
    SideCategoryMenu,
    GoodShow,
    Calendar,
    MessageCutShow
  },

  data: () => ({
    items: [
      {src: 'back1.jpeg'},
      {src: 'back2.jpeg'},
      {src: 'back3.jpeg'}
    ],
    highlighter: ["2022-12-04"],
    infoList: [],
    showCalendarFlag: false,
    lastGoodList: [],
    recommendList: [],
    searchCondition: null
  }),
  async mounted() {
    this.infoList = await getLastInfoList();
    let holidayResult = await getHolidayList();
    for (let i = 0; i < holidayResult.data.result.length; i++) {
      if (!this.highlighter.includes(holidayResult.data.result[i].noworkdate)) {
        this.highlighter.push(holidayResult.data.result[i].noworkdate)
      }
    }
    this.showCalendarFlag = true;
    this.lastGoodList = await getLastGoodList();
    this.recommendList = await getRecommendList();
  },
  methods: {
    goodSearch() {
      this.$store.dispatch('searchcondition/setCondition', {
        condition: {
          subCategoryName: null,
          keyword: this.searchCondition
        }
      });
      this.$router.push({
        name: "GoodSearch"
      });
    }
  }
};
</script>
<style>
.table_header_calendar {
  background-color: saddlebrown;
  width: 181px;
  height: 30px;
  display: flex; /* 使用 Flexbox 布局 */
  justify-content: center; /* 水平居中 */
  align-items: center; /* 垂直居中 */
  color: white;
  font-weight: bolder;
  padding: 0px;
  border-top: 1px solid black;
  border-left: 1px solid black;
  border-right: 1px solid black;
}

.table_header_info {
  width: 181px;
  display: flex;
  justify-content: space-between;
  text-align: center;
  padding: 0px;
  border-left: 1px solid black;
  border-right: 1px solid black;
  background-color: rgb(210, 180, 140);

}

.table_header_info_item {
  width: calc(100% / 7);
  color: saddlebrown;
}
</style>
