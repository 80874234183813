<template>
  <v-container>
    <v-row justify="center">
      <v-container style="max-width: 1280px">
        <v-row >
          <v-col cols="12" align="center">
            <h1>会員登録</h1>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="3">
            <p class="required">お名前</p>
          </v-col>
          <v-col cols="4">
            <v-text-field
                outlined
                dense
                label="姓"
                v-model="firstname"
                :error-messages="firstnameErrorMsg"
            ></v-text-field>
          </v-col>
          <v-col cols="4">
            <v-text-field
                outlined
                dense
                label="名"
                v-model="lastname"
                :error-messages="lastnameErrorMsg"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="3">
            <p class="required">フリガナ</p>
          </v-col>
          <v-col cols="4">
            <v-text-field
                outlined
                dense
                label="セイ"
                v-model="firstnamekana"
                :error-messages="firstnamekanaErrorMsg"
            ></v-text-field>
          </v-col>
          <v-col cols="4">
            <v-text-field
                outlined
                dense
                label="メイ"
                v-model="lastnamekana"
                :error-messages="lastnamekanaErrorMsg"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="3">
            <p class="required">郵便番号</p>
          </v-col>
          <v-col cols="4">
            <v-text-field
                style="max-width: 250px"
                outlined
                dense
                label="ハイフン不要"
                v-model="postcode"
                :error-messages="postCodeErrorMsg"
            ></v-text-field>
          </v-col>
          <v-col cols="2">
            <v-btn
                rounded
                color="blue"
                dark
                @click="autoAddress"
            >
              郵便番号検索
            </v-btn>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="3">
            <p class="required">住所</p>
          </v-col>
          <v-col cols="8">
            <v-text-field
                outlined
                dense
                v-model="address"
                :error-messages="addressErrorMsg"
            ></v-text-field>
          </v-col>
          <v-col cols="3"></v-col>
          <v-col cols="8">
            <v-text-field
                outlined
                dense
                v-model="addressExtend"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="3">
            <p class="required">電話番号</p>
          </v-col>
          <v-col cols="8">
            <v-text-field
                outlined
                dense
                label="ハイフン（-）不要"
                v-model="phone"
                :error-messages="phoneErrorMsg"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="3">
            <p class="required">メールアドレス</p>
          </v-col>
          <v-col cols="8">
            <v-text-field
                style="max-width: 500px"
                outlined
                dense
                label="info@example.com"
                v-model="mailaddress"
                :error-messages="mailaddressErrorMsg"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="3">
            <p class="required">初期パスワード</p>
          </v-col>
          <v-col cols="8">
            <v-text-field
                outlined
                dense
                label="半角英数字で8文字以上20文字以内"
                v-model="password"
                :error-messages="passwordErrorMsg"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="3">
            <p>メール通知を希望する</p>
          </v-col>
          <v-col cols="8">
            <v-checkbox dense label="クーポン、インベントなどの情報を登録したメールアドレスに送信する" v-model="mailsendflag">
            </v-checkbox>
          </v-col>
        </v-row>
        <v-row dense style="margin-top: 30px;">
          <v-col cols="6" align="center">
            <v-btn
                rounded
                color="gray"
                style="width:100px"
                @click="backToHome">
              ホームに戻る
            </v-btn>
          </v-col>
          <v-col cols="6" align="center">
            <v-btn
                rounded
                color="blue"
                dark
                style="width:100px"
                @click="registUser"
            >
              登録
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-row>
  </v-container>
</template>

<script>
import {autoAddress, registUser} from "@/domain/api/user";
import {checkMailAddress, containsOnlyAlphaNumeric, checkPostCode, checkPhoneNumber} from "@/utils/common";

export default {
  name: "RegistUser",
  data() {
    return {
      yearItems: Array.from(Array(93), (v, k) => (k + 1930) + "年"),
      monthItems: Array.from(Array(12), (v, k) => k + 1 < 10 ? "0" + (k + 1) + "月" : (k + 1) + "月"),
      dayItems: Array.from(Array(31), (v, k) => k + 1 < 9 ? "0" + (k + 1) + "日" : (k + 1) + "日"),
      mailaddress: null,
      password: null,
      firstname: null,
      lastname: null,
      firstnamekana: null,
      lastnamekana: null,
      sex: Number(0),
      birthdayyear: (new Date().getFullYear() - 1) + "年",
      birthdaymonth: "01月",
      birthdayday: "01日",
      postcode: null,
      phone: null,
      address: null,
      mailsendflag: false,
      firstnameErrorMsg: "",
      lastnameErrorMsg: "",
      firstnamekanaErrorMsg: "",
      lastnamekanaErrorMsg: "",
      sexErrorMsg: "",
      postCodeErrorMsg: "",
      passwordErrorMsg: "",
      phoneErrorMsg: "",
      addressErrorMsg: "",
      mailaddressErrorMsg: "",
      addressExtend: ""
    }
  },
  mounted() {
    this.$set(this, 'sex', 0);
  },
  methods: {
    backToHome() {
      this.$router.push({
        name: "PhoneHome"
      });
    },
    async autoAddress() {
      this.address = await autoAddress(this.postcode);
    },
    async registUser() {
      let errorFlag = false;

      if (this.mailaddress == null) {
        this.mailaddressErrorMsg = "メールアドレスを入力してください。"
        errorFlag = true;
      }
      if (!checkMailAddress(this.mailaddress)) {
        this.mailaddressErrorMsg = "メールアドレスを入力してください。"
        errorFlag = true;
      }
      if (this.password == null) {
        this.passwordErrorMsg = "パスワードを入力してください。"
        errorFlag = true;
      }
      if (!containsOnlyAlphaNumeric(this.password)) {
        this.passwordErrorMsg = "パスワードを入力してください。"
        errorFlag = true;
      }
      if (this.firstname == null || this.firstname == "") {
        this.firstnameErrorMsg = "姓を入力してください。"
        errorFlag = true;
      }
      if (this.lastname == null || this.lastname == "") {
        this.lastnameErrorMsg = "名を入力してください。"
        errorFlag = true;
      }
      if (this.firstnamekana == null || this.firstnamekana == "") {
        this.firstnamekanaErrorMsg = "セイを入力してください。"
        errorFlag = true;
      }
      if (this.lastnamekana == null || this.lastnamekana == "") {
        this.lastnamekanaErrorMsg = "メイを入力してください。"
        errorFlag = true;
      }
      if (this.postcode == null || this.postcode == "") {
        this.postCodeErrorMsg = "郵便番号を入力してください。"
        errorFlag = true;
      }
      if (!checkPostCode(this.postcode)) {
        this.postCodeErrorMsg = "郵便番号を入力してください。"
        errorFlag = true;
      }
      if (this.address == null || this.address == "") {
        this.addressErrorMsg = "住所を入力してください。"
        errorFlag = true;
      }
      if (this.phone == null) {
        this.phoneErrorMsg = "電話番号を入力してください。"
        errorFlag = true;
      }
      if (!checkPhoneNumber(this.phone)) {
        this.phoneErrorMsg = "電話番号を入力してください。"
        errorFlag = true;
      }

      if (errorFlag) {
        return;
      }
      try {
        await registUser({
          mailaddress: this.mailaddress,
          password: this.password,
          name: this.firstname + " " + this.lastname,
          namekana: this.firstnamekana + " " + this.lastnamekana,
          sex: 18,
          birthday: this.birthdayyear.replace("年", "") + this.birthdaymonth.replace("月", "") + this.birthdayday.replace("日", ""),
          postcode: this.postcode,
          address: this.address+this.addressExtend,
          phone: this.phone,
          mailsendflag: Number(this.mailsendflag)
        })
      } catch (e) {
        // alert("ユーザー新規失敗しました、お手数ですが、会社に電話して確認お願いします");
      }
      alert("登録を完了しました。");
      this.$router.push({
        name: "PhoneHome"
      });
    }
  }
}
</script>

<style scoped>
.required::after {
  content: "*";
  color: red;
  position: relative;
  left: 0;
}

</style>
