<template>
  <v-container>
    <v-row justify="center">
      <v-container style="max-width: 1280px">
        <v-row justify="center">
          <div style="font-size: 25px">
            <b>ショッピングカート</b></div>
        </v-row>
        <v-row style="margin-top: 50px" justify="center" v-if="goodItems.length == 0">
          <p style="font-size: 20px">ショッピングカートに商品はありません</p>
        </v-row>
        <v-row v-for="(item,index) in this.goodItems" :key="index">
          <PhoneCartGoodShow :item="item"></PhoneCartGoodShow>
        </v-row>
        <v-row v-if="goodItems.length != 0" class="mt-10" dense>
          <v-col align="center">
            <p style="font-size: 20px;">合計（税込）: <b style="color: red">¥{{ allPrice() }}</b></p>
          </v-col>
        </v-row>
        <v-row v-if="goodItems.length != 0" dense>
          <v-col cols="12" align="center">
            <v-btn
                width="80%"
                color="green"
                dark
                class="btn_menu"
                @click="goToPay"
                :disabled="this.computedPrice <= 0"
            >
              ご購入手続きに進む
            </v-btn>
          </v-col>
        </v-row>
        <v-row dense style="margin-top: 30px">
          <v-col cols="12" align="center">
            <v-btn
                width="80%"
                color="#FFD814"
                dark
                class="btn_menu"
                @click="backToHome"
            >
              <div style="color: black">買い物を続ける</div>
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-row>
  </v-container>
</template>

<script>
import {mapGetters} from 'vuex'
import {fixPrice} from "@/utils/common";
import {checkStock} from "@/domain/api/good"
import {urlImgHead} from "@/domain/RestClient"
import PhoneCartGoodShow from "@/components/CommonUtils/PhoneCartGoodShow.vue";

export default {
  name: 'CartPage',


  data: () => ({
    urlImgHead,
    computedPrice: 0
  }),
  components: {
    PhoneCartGoodShow
  },
  computed: {
    ...mapGetters({
      goodItems: 'cart/goodItems',
    }),
  },
  methods: {
    backToHome() {
      if (this.$route.path != '/phone') {
        this.$router.push({
          name: "PhoneHome"
        });
      }
    },
    fixNumber(price) {
      return fixPrice(price)
    },
    async goToPay() {
      let searchList = [];
      for (let i = 0; i < this.goodItems.length; i++) {
        searchList.push({"goodid": this.goodItems[i].goodid, "stock": Number(this.goodItems[i].number)});
      }
      let response = await checkStock({searchList});
      if (response.data.resultCode != "200") {
        let targetGoodId = response.data.stockInfo.split(";")[0];
        let targetGoodNumber = response.data.stockInfo.split(";")[1];
        for (let i = 0; i < this.goodItems.length; i++) {
          if (this.goodItems[i].goodid == targetGoodId) {
            if (targetGoodNumber == "0") {
              alert("商品：" + this.goodItems[i].detail.goodname + "\n在庫:切れました\n数量を修正して上会計お願いします。");
              return;
            } else {
              alert("商品：" + this.goodItems[i].detail.goodname + "\n在庫:" + targetGoodNumber + "件\n数量を修正して上会計お願いします。");
              return;
            }
          }
        }
      }
      if (this.$store.state.logininfo.userId != null && this.$store.state.logininfo.userId != "") {
        if (this.$route.path != '/phone/pay') {
          await this.$router.push({
            name: "PhonePay"
          });
        }
      } else {
        if (this.$route.path != '/phone/member') {
          await this.$router.push({
            name: "PhoneMember"
          });
        }
      }
    },
    allPrice() {
      if (this.goodItems == null || this.goodItems.length == 0) {
        this.computedPrice = 0;
        return 0
      } else {
        let allCount = 0;
        for (let i = 0; i < this.goodItems.length; i++) {
          allCount = allCount + (this.goodItems[i].number * this.goodItems[i].detail.price)
        }
        this.computedPrice = allCount;
        return fixPrice(allCount);
      }
    }
  }
};
</script>
<style>
.tableItem {
  font-size: 16px !important;
  margin-bottom: 0px !important;
}
</style>
