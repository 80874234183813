<template>
  <v-container style="width: 100%;margin-top: 30px" v-if="goodDetail != null">
    <div>
      <div width="275px" height="260px" style="display: flex;justify-content: center;align-items: center;">
        <img width="200px" height="150px" :src="urlImgHead + goodDetail.imgurl" style="cursor: pointer" @click="goToGoodDetail(goodDetail.goodid)">
        </div>
      <p class="good_name" style="padding-left:50px;margin-bottom:0px !important;font-size:15px;height: 70px;overflow:hidden;cursor: pointer;" @click="goToGoodDetail(goodDetail.goodid)">{{ goodDetail.goodname }}</p>
      <p v-if="goodDetail.stock != 0" style="color: red;text-align: center"><b style="font-size: 20px">￥{{ showprice }}</b><span style="font-size: 13px;color: black">（税込）</span></p>
      <p v-else style="color: black;text-align: center"><b style="font-size: 20px">入荷待ち</b></p>
    </div>
<!--    <div style="width: 100%;text-align: center">-->
<!--      <v-btn-->
<!--          color="#FFD814"-->
<!--          style="color:whitesmoke"-->
<!--          @click="addGoodCart(goodDetail.goodid,goodDetail)"-->
<!--          :disabled="stockFlag"-->
<!--      >-->
<!--        <div style="color: black">カートに入れる</div>-->
<!--      </v-btn>-->
<!--    </div>-->
  </v-container>
</template>

<script>
import {mapGetters} from 'vuex'
import {fixPrice} from "@/utils/common";
import { urlImgHead } from "@/domain/RestClient"

export default {
  name: "GoodShow",
  props: {
    goodDetail: Object
  },
  data(){
    return{
      urlImgHead
    }
  },
  computed: {
    ...mapGetters({
      goodItems: 'cart/goodItems',
    }),
    stockFlag() {
      if (this.goodDetail.stock == 0) {
        return true
      } else {
        return false
      }
    },
    showprice() {
      if (this.goodDetail == null) {
        return 0
      } else {
        return fixPrice(this.goodDetail.price)
      }
    },
  },
  methods: {
    goToGoodDetail(goodid) {
      this.$router.push({name: 'GoodDetail', params: {goodId: goodid}})
    },
    addGoodCart(goodid,goodDetail) {
      for (let i = 0; i < this.goodItems.length; i++) {
        if (this.goodItems[i].goodid == goodid) {
          this.$router.push({name: 'CartPage'});
          return;
        }
      }
      this.$store.state.cart.goodItems.push({goodid: goodid, number: 1, detail: goodDetail});
      this.$store.dispatch('cart/setGoodItems', {goodItems: this.$store.state.cart.goodItems})
      this.$router.push({name: 'CartPage'});
    }
  }
}
</script>

<style scoped>
.good_name:hover{
  color: red;
  text-decoration: underline;
}
</style>
