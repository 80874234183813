<template>
  <v-container fluid>
    <v-row dense>
      <p style="font-size: 12px;color: darkgray;margin-bottom: 0px">{{ infoDetail.createdate }}</p>
    </v-row>
    <v-row dense>
      <p style="font-size: 16px;color: black;cursor: pointer" @click="goToInfoDetail(infoDetail.infoid)"><u>{{ infoDetail.title }}</u></p>
    </v-row>
    <v-row dense>
      <span style="white-space: pre-wrap;">
        {{ infoDetail.textbody.replaceAll('\\n','\n') }}
      </span>
    </v-row>
    <v-row>
      <v-divider></v-divider>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "PhoneInfoShotShow",
  props: {
    infoDetail: Object
  },
  methods: {
    goToInfoDetail(infoid) {
      this.$router.push({name: 'PhoneInfoDetail', params: {infoId: infoid}})
    }
  }
}
</script>

<style scoped>

</style>
