<template>
  <v-container fluid>
    <v-row justify="center">
      <v-container style="max-width: 1280px">
        <v-row justify="start">
          <p style="font-size: 24px">ショッピングカート</p>
        </v-row>
        <v-row style="margin-top: 50px" v-if="goodItems.length == 0">
          <p style="font-size: 18px">ショッピングカートに商品はありません</p>
        </v-row>
        <v-row justify="center" v-if="goodItems.length != 0">
          <v-data-table
              hide-default-footer
              :headers="headers"
              :items="goodItems"
              class="elevation-1"
              style="width: 100%;max-width: 1280px"
          >
            <template v-slot:body="{ items }">
              <tbody>
              <tr
                  v-for="item in items"
                  :key="item.detail.goodid"
              >
                <td>
                  <v-row justify="center">
                    <v-col cols="4">
                      <img :src="urlImgHead+item.detail.imgurl" width="200px" height="150px"/>
                    </v-col>
                    <v-col cols="8">
                      <p class="tableItem" style="margin-top: 20px;font-size: 16px !important;"><b>{{ item.detail.goodname }}</b></p>
                    </v-col>
                  </v-row>
                </td>
                <td><p class="tableItem">¥{{ fixNumber(item.detail.price) }}</p></td>
                <td>
                  <p class="tableItem">
                    <span class="price_number">{{item.number}}</span>
                  </p>
                </td>
                <td><p class="tableItem">¥{{ fixNumber(item.number * item.detail.price) }}</p></td>
                <td>
                  <v-icon
                      big
                      @click="deleteItem(item.detail.goodid)"
                  >
                    mdi-delete
                  </v-icon>
                </td>
              </tr>
              </tbody>
            </template>
          </v-data-table>
        </v-row>
        <v-row style="max-width: 1280px;margin-top: 50px;" v-if="goodItems.length != 0">
          <v-col cols="4"></v-col>
          <v-col cols="4">
            <p style="font-size: 20px;">合計（税込）: <b style="color: red">¥{{ allPrice() }}</b></p>
          </v-col>
          <v-col cols="2">
            <v-btn
                color="#FFD814"
                dark
                class="btn_menu"
                @click="goToTop"
            >
              <div style="color: black">買い物を続ける</div>
            </v-btn>
          </v-col>
          <v-col cols="2">
            <v-btn
                color="green"
                dark
                class="btn_menu"
                @click="goToPay"
                :disabled="this.computedPrice <= 0"
            >
              ご購入手続きに進む
            </v-btn>
          </v-col>
        </v-row>
        <v-row style="max-width: 1280px;margin-top: 50px;" v-if="goodItems.length == 0">
          <v-col cols="10"></v-col>
          <v-col cols="2">
            <v-btn
                color="#FFD814"
                dark
                class="btn_menu"
                @click="goToTop"
            >
              <div style="color: black">買い物を続ける</div>
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-row>
  </v-container>
</template>

<script>
import {mapGetters} from 'vuex'
import {fixPrice} from "@/utils/common";
import {checkStock} from "@/domain/api/good"
import {urlImgHead} from "@/domain/RestClient"

export default {
  name: 'CartPage',


  data: () => ({
    urlImgHead,
    computedPrice: 0,
    headers: [
      {
        text: '商品情報',
        align: 'start',
        sortable: false,
        value: 'name',
      },
      {text: '価格（税込）', sortable: false, value: 'price', width: '180px'},
      {text: '数量', sortable: false, value: 'count', width: '100px'},
      {text: '小計（税込）', sortable: false, value: 'goodPrice', width: '150px'},
      {text: '削除', sortable: false, value: 'delete', width: '100px'},
    ],
  }),
  computed: {
    ...mapGetters({
      goodItems: 'cart/goodItems',
    }),
  },
  methods: {
    deleteItem(goodId) {
      for (let i = 0; i < this.goodItems.length; i++) {
        if (this.goodItems[i].goodid == goodId) {
          this.goodItems.splice(i, 1);
        }
      }
    },
    fixNumber(price) {
      return fixPrice(price)
    },
    goToTop() {

      this.$router.push({
        name: "Home"
      });

    },
    async goToPay() {
      if (this.$store.state.cart.goodItems.length == 0) {
        alert("カートに商品を追加してください");
        return;
      }
      let searchList = [];
      for (let i = 0; i < this.goodItems.length; i++) {
        searchList.push({"goodid": this.goodItems[i].goodid, "stock": Number(this.goodItems[i].number)});
      }
      let response = await checkStock({searchList});
      if (response.data.resultCode != "200") {
        let targetGoodId = response.data.stockInfo.split(";")[0];
        let targetGoodNumber = response.data.stockInfo.split(";")[1];
        for (let i = 0; i < this.goodItems.length; i++) {
          if (this.goodItems[i].goodid == targetGoodId) {
            if (targetGoodNumber == "0") {
              alert("商品：" + this.goodItems[i].detail.goodname + "\n在庫:切れました\n数量を修正して上会計お願いします。");
              return;
            } else {
              alert("商品：" + this.goodItems[i].detail.goodname + "\n在庫:" + targetGoodNumber + "件\n数量を修正して上会計お願いします。");
              return;
            }
          }
        }
      }
      if (this.$store.state.logininfo.userId != null && this.$store.state.logininfo.userId != "") {
        if (this.$route.path != '/pay') {
          await this.$router.push({
            name: "Pay"
          });
        }
      } else {
        if (this.$route.path != '/member') {
          await this.$router.push({
            name: "Member"
          });
        }
      }

    },
    allPrice() {
      if (this.goodItems == null || this.goodItems.length == 0) {
        this.computedPrice = 0;
        return 0
      } else {
        let allCount = 0;
        for (let i = 0; i < this.goodItems.length; i++) {
          allCount = allCount + (this.goodItems[i].number * this.goodItems[i].detail.price)
        }
        this.computedPrice = allCount;
        return fixPrice(allCount);
      }
    }
  }
};
</script>
<style>
.tableItem {
  font-size: 16px !important;
  margin-bottom: 0px !important;
}

.price_number input {
  text-align: center;
}
</style>
