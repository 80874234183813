<template>
  <v-container style="width: 100%;" v-if="goodDetail != null">
    <div style="cursor: pointer" @click="goToGoodDetail(goodDetail.goodid)">
      <img width="80%" height="100px" :src="urlImgHead + goodDetail.imgurl">
      <p class="good_name" style="height: 70px;font-size:15px;cursor: pointer;overflow:hidden;">{{ goodDetail.goodname }}</p>
      <p v-if="goodDetail.stock != 0" style="color: red;text-align: center"><b style="font-size: 20px">￥{{ showprice }}</b><span style="font-size: 13px;color: black">（税込）</span></p>
      <p v-else style="color: black;text-align: center"><b style="font-size: 20px">入荷待ち</b></p>
    </div>
  </v-container>
</template>

<script>
import {mapGetters} from 'vuex'
import {fixPrice} from "@/utils/common";
import { urlImgHead } from "@/domain/RestClient"

export default {
  name: "PhoneGoodShow",
  props: {
    goodDetail: Object
  },
  data(){
    return{
      urlImgHead
    }
  },
  computed: {
    ...mapGetters({
      goodItems: 'cart/goodItems',
    }),
    stockFlag() {
      if (this.goodDetail.stock == 0) {
        return true
      } else {
        return false
      }
    },
    showprice() {
      if (this.goodDetail == null) {
        return 0
      } else {
        return fixPrice(this.goodDetail.price)
      }
    },
  },
  methods: {
    goToGoodDetail(goodid) {
      this.$router.push({name: 'PhoneGoodDetail', params: {goodId: goodid}})
    },
    addGoodCart(goodid,goodDetail) {
      for (let i = 0; i < this.goodItems.length; i++) {
        if (this.goodItems[i].goodid == goodid) {
          this.$router.push({name: 'CartPage'});
          return;
        }
      }
      this.$store.state.cart.goodItems.push({goodid: goodid, number: 1, detail: goodDetail});
      this.$store.dispatch('cart/setGoodItems', {goodItems: this.$store.state.cart.goodItems})
      this.$router.push({name: 'CartPage'});
    }
  }
}
</script>

<style scoped>
.good_name:hover{
  color: red;
  text-decoration: underline;
}
</style>
