const namespaced = true;

const defaultData = {
    condition: null
};

const getState = () => ({
    ...defaultData
});

export default {
    namespaced,
    state: getState(),
    mutations: {
        setCondition(state, payload) {
            state.condition = payload.condition;
        },
        init(state) {
            Object.assign(state, defaultData);
        }
    },
    getters: {
        condition: state => state.condition
    },
    actions: {
        setCondition({commit}, {condition}) {
            commit("setCondition", {condition});
        },
        refresh({commit}) {
            commit("init");
        }
    }
}
