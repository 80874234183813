<template>
  <v-container>
    <v-row>
      <v-col cols="3">
        {{ infoDetail.createdate.substr(0,10) }}
      </v-col>
      <v-col cols="9">
        <div @click="goToInfoDetail(infoDetail.infoid)"><p style="font-size: 16px;margin-bottom: 0px;cursor: pointer">
          <u>{{ infoDetail.title }}</u></p></div>
        <p>{{ infoDetail.textbody.toString().replaceAll("\\n", " ").substring(0, 50)+"..." }}</p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "PhoneMessageCutShow",
  methods: {
    goToInfoDetail(infoid) {
      this.$router.push({name: 'PhoneInfoDetail', params: {infoId: infoid}})
    }
  },
  props: {
    infoDetail: Object
  },
}
</script>

<style scoped>

</style>
