const namespaced = true;

const defaultData = {
    orderDetail: null,
    payMethod: null
};

const getState = () => ({
    ...defaultData
});

export default {
    namespaced,
    state: getState(),
    mutations: {
        setOrderDetail(state, payload) {
            state.orderDetail = payload.orderDetail;
        },
        setPayMethod(state, payload) {
            state.payMethod = payload.payMethod;
        },
        init(state) {
            Object.assign(state, defaultData);
        }
    },
    getters: {
        orderDetail: state => state.orderDetail,
        payMethod: state => state.payMethod
    },
    actions: {
        setOrderDetail({commit}, {orderDetail}) {
            commit("setOrderDetail", {orderDetail});
        },
        setPayMethod({commit}, {payMethod}) {
            commit("setPayMethod", {payMethod});
        },
        refresh({commit}) {
            commit("init");
        }
    }
}
