import {get,post} from "@/domain/RestClient";

export const getLastGoodList = async () => {
    try {
        const response = await get("/good/lastlist");
        return response.data.result;
    } catch (error) {
        console.log(error)
    }
};

export const getRecommendList = async () => {
    try {
        const response = await get("/good/recommendlist");
        return response.data.goodList;
    } catch (error) {
        console.log(error)
    }
};

export const getGoodDetail = async (goodid) => {
    try {
        const response = await get("/good/detail/" + goodid);
        return response.data.result;
    } catch (error) {
        console.log(error)
    }
};

export const getGoodList = async (condition) => {
    try {
        const response = await get("/good/getgoodlist", {params: condition});
        return response.data.result;
    } catch (error) {
        console.log(error)
    }
};

export const checkStock = async payload =>{
    try {
        const response = await post("good/checkStock", payload);
        return response;
    } catch (error) {
        console.log(error)
    }
}
