<template>
  <v-container fluid>
    <v-row>
      <v-container style="max-width: 1280px" v-if="goodDetail != null">
        <v-row cols="12" justify="start">
          <p style="font-size: 16px"><a href="/">ホーム</a> ＞ <a style="text-decoration: underline;"
                                                                 @click="searchPage">{{
              goodDetail.categorysubname
            }}</a> >
            {{ goodDetail.maker }}</p>
        </v-row>
        <v-row cols="12" justify="center">
          <v-col cols="6" class="pl-10">
            <img height="240px" width="320px"
                 :src="urlImgHead+goodDetail.imgurl"/>
          </v-col>
          <v-col cols="6">
            <v-row>
              <p style="font-size: 20px;margin-top: 20px;padding-left: 2%"><b>{{ goodDetail.goodname }}</b></p>
            </v-row>
            <v-row dense>
              <v-col cols="4">
                <p style="font-size: 16px;margin-bottom: 0;padding-bottom: 0;padding-left: 2%">JANコード:</p>
              </v-col>
              <v-col cols="8" align="start">
                <p style="font-size: 16px;margin-bottom: 0;padding-bottom: 0">{{ goodDetail.jancode }}</p>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="4">
                <p style="font-size: 16px;margin-bottom: 0;padding-bottom: 0;padding-left: 2%">価格：</p>
              </v-col>
              <v-col cols="8" align="start">
                <p style="font-size: 20px;margin-bottom: 0;padding-bottom: 0"><b style="color: red">￥{{ showprice }}</b><span
                    style="color: black;font-size: 13px">（税込）</span></p>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="4">
                <p style="font-size: 16px;margin-bottom: 0;padding-bottom: 0;padding-left: 2%">在庫状況：</p>
              </v-col>
              <v-col cols="8" align="start">
                <p style="font-size: 16px;margin-bottom: 0;padding-bottom: 0">{{ showStock }}</p>
              </v-col>
            </v-row>
            <v-row v-show="hideFlag">
              <v-col cols="3">
                <v-select
                    style="max-width: 100px;"
                    outlined
                    dense
                    :items="buyCountList"
                    v-model="buyCount"
                    label="購入数"
                ></v-select>
              </v-col>
              <v-col cols="9" align="start" class="pl-15">
                <v-btn
                    style="color:whitesmoke"
                    color="#FFD814"
                    :disabled="stockFlag"
                    @click="addGoodCart()"
                >
                  <div style="color: black">カートに入れる</div>
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row cols="12" justify="center">
          <v-col cols="6">
            <p class="contentBody" style="font-size: 16px !important;"><b>商品説明：</b></p>
            <p class="contentBody" style="white-space: pre-line;font-size: 16px !important">
              {{ goodDetail.goodinfo }}</p>
          </v-col>
          <v-col cols="6">
            <p class="contentBody" style="font-size: 16px !important;">■送料無料（沖縄・一部離島を除く）</p>
            <p class="contentBody" style="font-size: 16px !important;">
              ■15時までのご注文につきましては、当日出荷致します。</p>
            <p class="contentBody" style="font-size: 16px !important;">
              ■ご注文確認メールやお問い合わせメールの返信が届かない場合は「迷惑メールフォルダ」の確認をお願い致します。</p>
            <p class="contentBody" style="font-size: 16px !important;">
              ■何かご不明な点などがございましたら、お気軽にお問い合わせください。</p>
            <p class="contentBody" style="font-size: 16px !important;">お問い合せ先：sale@akiba109.com</p>
          </v-col>
        </v-row>
      </v-container>
    </v-row>
  </v-container>
</template>

<script>
import {getGoodDetail} from "@/domain/api/good";
import {fixPrice} from "@/utils/common";
import {mapGetters} from 'vuex'
import {urlImgHead} from "@/domain/RestClient"

export default {
  name: 'GoodDetail',
  data: () => ({
    showFlag1: false,
    showFlag2: false,
    goodDetail: null,
    buyCount: 1,
    urlImgHead,
    buyCountList: [],
    hideFlag: true
  }),
  async mounted() {
    const goodid = this.$route.path.split("/")[2];
    this.goodDetail = await getGoodDetail(goodid);
    for (let i = 1; i <= this.goodDetail.stock; i++) {
      this.buyCountList.push(i);
    }
    if (this.goodDetail.stock === 0) {
      this.hideFlag = false
    }
  },
  methods: {
    async searchPage() {
      await this.$store.dispatch('searchcondition/setCondition', {
        condition: {
          subCategoryName: this.goodDetail.categorysubname,
          keyword: null
        }
      });
      if (this.$route.path != "/goodSearch") {
        await this.$router.push({
          name: "GoodSearch"
        });
      }
    },
    addGoodCart() {
      if (this.buyCount > this.goodDetail.stock) {
        alert("現在該当する商品の在庫は" + this.goodDetail.stock + "です。\n購入数を修正の上でカートに追加お願いします")
      }
      for (let i = 0; i < this.goodItems.length; i++) {
        if (this.goodItems[i].goodid == this.goodDetail.goodid) {
          this.$store.state.cart.goodItems.splice(i, 1);
        }
      }
      this.$store.state.cart.goodItems.push({
        goodid: this.goodDetail.goodid,
        number: this.buyCount,
        detail: this.goodDetail
      });
      this.$store.dispatch('cart/setGoodItems', {goodItems: this.$store.state.cart.goodItems})
      this.$router.push({name: 'CartPage'});
    }
  },
  computed: {
    ...mapGetters({
      goodItems: 'cart/goodItems',
    }),
    stockFlag() {
      if (this.goodDetail == null || this.goodDetail.stock <= 0 || this.buyCount <= 0) {
        return true
      } else {
        return false
      }
    },
    showprice() {
      if (this.goodDetail == null) {
        return 0
      } else {
        return fixPrice(this.goodDetail.price)
      }
    },
    showStock() {
      if (this.goodDetail == null || this.goodDetail.stock <= 0) {
        return "入荷待ち"
      } else if (this.goodDetail.stock <= 3) {
        return "残りわずか";
      } else {
        return "在庫あり";
      }
    }
  }
};
</script>
<style scoped>

.contentBody {
  font-size: 18px;
  margin-bottom: 0px;
}
</style>
