import Vue from 'vue'
import Vuex from 'vuex'
import logininfo from './logininfo'
import categoryInfo from './categoryInfo'
import cart from './cart'
import searchcondition from './searchcondition'
import createPersistedState from 'vuex-persistedstate'
import orderinfo from './orderinfo'
import menu from "@/store/menu";

Vue.use(Vuex)

const modules = {
    logininfo,
    categoryInfo,
    cart,
    searchcondition,
    orderinfo,
    menu
}


export default new Vuex.Store({
    modules: modules,
    plugins: [createPersistedState(
        {
            storage: window.sessionStorage
        })],
})
