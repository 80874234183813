import { render, staticRenderFns } from "./PhonePay.vue?vue&type=template&id=75e97350&scoped=true&"
import script from "./PhonePay.vue?vue&type=script&lang=js&"
export * from "./PhonePay.vue?vue&type=script&lang=js&"
import style0 from "./PhonePay.vue?vue&type=style&index=0&id=75e97350&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "75e97350",
  null
  
)

export default component.exports