<template>
  <v-container fluid>
    <v-row justify="center">
      <v-container style="max-width: 1280px">
        <v-row cols="12" dense>
          <v-col cols="12">
            <p style="margin-bottom:0px;padding-bottom:0px;font-size: 24px">ご注文の確認</p>
          </v-col>
        </v-row>
        <v-row class="mt-0" cols="12" v-for="(item,index) in goodItems" :key="index">
          <CartGoodShowForPay :item="item"></CartGoodShowForPay>
        </v-row>
        <v-row cols="12" style="margin-top: 30px">
          <v-col cols="12" align="center">
            <p style="font-size: 18px">{{ commissionPro(this.payMethod, allPriceNumber()) }}</p>
            <p style="font-size: 18px">お支払い金額（税込）: <b style="color: red"> ¥{{ allPrice() }}</b></p>
          </v-col>
        </v-row>
        <v-row cols="12" dense>
          <v-col coks="12" align="center">
            <v-card>
              <v-card-title>
                <v-row>
                  <v-col cols="6" align="start">
                    <p>お届け先</p>
                  </v-col>
                  <v-col cols="6" align="end">
                    <v-btn
                        width="50px"
                        rounded
                        @click="backToEdit()">
                      修正
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-title>
              <v-card-text>
                <v-row dense>
                  <v-col cols="3">
                    <p class="titleBodyConfirm">お名前</p>
                  </v-col>
                  <v-col cols="9" align="start">
                    <p class="titleBodyConfirm">{{ this.firstname + " " + this.lastname }}
                      ({{ this.firstnamekana + " " + this.lastnamekana }})&nbsp;&nbsp;&nbsp;&nbsp;様</p>
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col cols="3">
                    <p class="titleBodyConfirm">住所</p>
                  </v-col>
                  <v-col cols="9" align="start">
                    <p class="titleBodyConfirm">〒{{ this.postcode }}&nbsp;&nbsp;&nbsp;&nbsp;{{ this.address }}</p>
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col cols="3">
                    <p class="titleBodyConfirm">電話番号</p>
                  </v-col>
                  <v-col cols="9" align="start">
                    <p class="titleBodyConfirm">{{ this.phone }}</p>
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col cols="3">
                    <p class="titleBodyConfirm">メールアドレス</p>
                  </v-col>
                  <v-col cols="9" align="start">
                    <p class="titleBodyConfirm">{{ this.mailaddress }}</p>
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col cols="3">
                    <p class="titleBodyConfirm">決済方法</p>
                  </v-col>
                  <v-col cols="7" align="start">
                    <p class="titleBodyConfirm">{{ this.payMethod == "0" ? "代引き" : "銀行振込" }}</p>
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col cols="3">
                    <p class="titleBodyConfirm">備考</p>
                  </v-col>
                  <v-col cols="7" align="start">
                    <p class="titleBodyConfirm">{{ this.note }}</p>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row style="margin-top: 30px">
          <v-col cols="12" align="center">
            <v-btn
                :disabled="orderFlag"
                :loading="orderFlag"
                rounded
                color="blue"
                width="200px"
                @click="goToOrder()">
              <div style="color: white!important;">注文を確定する</div>
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-row>
  </v-container>
</template>

<script>
import {mapGetters} from 'vuex'
import {fixPrice, commissionPro, commissionProNumber} from "@/utils/common";
import {orderPost} from "@/domain/api/order"
import {urlImgHead} from "@/domain/RestClient"
import CartGoodShowForPay from "@/components/CommonUtils/CartGoodShowForPay.vue";

export default {
  name: 'PhonePayConfirm',
  components: {
    CartGoodShowForPay
  },
  data: () => ({
    orderFlag: false,
    urlImgHead,
    headers: [
      {
        text: '商品情報',
        align: 'start',
        sortable: false,
        value: 'name',
      },
      {text: '価格（税込）', sortable: false, value: 'price', width: '180px'},
      {text: '数量', sortable: false, value: 'count', width: '100px'},
      {text: '小計（税込）', sortable: false, value: 'goodPrice', width: '180px'}
    ],
    firstname: null,
    lastname: null,
    firstnamekana: null,
    lastnamekana: null,
    phone: null,
    mailaddress: null,
    postcode: null,
    address: null,
    note: null,
  }),
  async mounted() {
    this.firstname = this.orderDetail.firstname;
    this.lastname = this.orderDetail.lastname;
    this.firstnamekana = this.orderDetail.firstnamekana;
    this.lastnamekana = this.orderDetail.lastnamekana;
    this.phone = this.orderDetail.phone;
    this.mailaddress = this.orderDetail.mailaddress;
    this.postcode = this.orderDetail.postcode;
    this.address = this.orderDetail.address;
    this.note = this.orderDetail.note;
  },
  computed: {
    ...mapGetters({
      goodItems: 'cart/goodItems',
      orderDetail: 'orderinfo/orderDetail',
      payMethod: 'orderinfo/payMethod'
    }),
  },
  methods: {
    backToEdit() {
      this.$router.push({
        name: "PhonePay"
      });
    },
    fixNumber(price) {
      return fixPrice(price)
    },
    commissionPro,
    async goToOrder() {
      this.orderFlag = true;
      const goodlistjson = JSON.stringify(this.goodItems);
      let price = 0;
      if (this.goodItems == null || this.goodItems.length == 0) {
        price = 0;
      } else {
        for (let i = 0; i < this.goodItems.length; i++) {
          price = price + (this.goodItems[i].number * this.goodItems[i].detail.price)
        }
      }
      price = price + commissionProNumber(this.payMethod, this.allPriceNumber());
      try {
        await orderPost({
          firstname: this.firstname,
          lastname: this.lastname,
          firstnamekana: this.firstnamekana,
          lastnamekana: this.lastnamekana,
          phone: this.phone,
          mailaddress: this.mailaddress,
          postcode: this.postcode,
          address: this.address,
          note: this.note,
          payMethod: this.payMethod,
          price: price,
          goodlistjson: goodlistjson,
          loginid: this.$store.state.logininfo.loginId,
          commission: commissionProNumber(this.payMethod, this.allPriceNumber())
        });
      } catch (e) {
        alert("注文失敗しました")
        return;
      }
      this.orderFlag = false;
      this.$store.state.cart.goodItems = [];
      alert("注文が確定しました。ありがとうございました。")
      await this.$router.push({
        name: "PhoneHome"
      });
    },
    allPrice() {
      if (this.goodItems == null || this.goodItems.length == 0) {
        return 0
      } else {
        let allCount = 0;
        for (let i = 0; i < this.goodItems.length; i++) {
          allCount = allCount + (this.goodItems[i].number * this.goodItems[i].detail.price)
        }
        allCount = allCount + commissionProNumber(this.payMethod, this.allPriceNumber())
        return fixPrice(allCount);
      }
    },
    allPriceNumber() {
      if (this.goodItems == null || this.goodItems.length == 0) {
        return 0
      } else {
        let allCount = 0;
        for (let i = 0; i < this.goodItems.length; i++) {
          allCount = allCount + (this.goodItems[i].number * this.goodItems[i].detail.price)
        }
        return allCount;
      }
    }

  }
};
</script>
<style>
.titleBodyConfirm {
  font-size: 17px;
}

.contentBody {
  font-size: 15px;
  margin-bottom: 5px !important;
}
</style>
