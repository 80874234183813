import {get, post} from "@/domain/RestClient";
import {getWithToken} from "@/domain/RestClient";
import {postWithToken} from "@/domain/RestClient";

export const registUser = async payload => {
    try {
        const response = await post("user/regist", payload);
        return response;
    } catch (error) {
        console.log(error)
    }
};

export const login = async payload => {
    try {
        const response = await post("user/login", payload);
        if (response.data.resultCode == "400") {
            throw new DOMException("認証失敗");
        }
        return response;
    } catch (error) {
        console.log(error)
    }
};

export const getUserInfo = async loginId => {
    try {
        const response = await getWithToken("author/userdetail/" + loginId);
        return response.data.result;
    } catch (error) {
        console.log(error)
    }
};

export const updateUser = async payload => {
    try {
        const response = await postWithToken("author/user/update", payload);
        return response;
    } catch (error) {
        console.log(error)
    }
};

export const autoAddress = async (postCode) => {
    try {
        const response = await get("https://zipcloud.ibsnet.co.jp/api/search?zipcode=" + postCode);
        const result = response.data.results[0]
        return result.address1 + result.address2 + result.address3;
    } catch (e) {
        console.log(e)
    }
}
