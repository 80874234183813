export const fixPrice = (price) => {
    const result = [];
    let counter = 0;
    price = (price || 0).toString().split('')
    for (let i = price.length - 1; i >= 0; i--) {
        counter++;
        result.unshift(price[i])
        if (!(counter % 3) && i != 0) {
            result.unshift(',')
        }
    }
    return result.join('');
}

export const postMethodString = (postCode) => {
    if(postCode == null){
        return "";
    }
    if (Number(postCode) == 0) {
        return "ヤマト運輸"
    } else if (Number(postCode) == 1) {
        return "日本郵便"
    } else {
        return "";
    }
}

export const commissionPro = (paymethod, allprice) => {
    if(Number(paymethod) != 0){
        return ""
    }else{
        if(Number(allprice) > 0 && Number(allprice) <100000){
            return "代引き手数料：¥550"
        }else if(Number(allprice) >= 100000 && Number(allprice) < 300000){
            return "代引き手数料:¥1,100"
        }else{
            return "代引き上限金額は30万以内となります。"
        }
    }
}

export const commissionProNumber = (paymethod, allprice) => {
    if(Number(paymethod) != 0){
        return 0
    }else{
        if(Number(allprice) > 0 && Number(allprice) <100000){
            return 550
        }else if(Number(allprice) >= 100000 && Number(allprice) < 300000){
            return 1100
        }else{
            return 9999
        }
    }
}

export const checkMailAddress = (string)=>{
    const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    // 使用正则表达式测试输入的字符串
    return pattern.test(string);
}

export const containsOnlyAlphaNumeric=(str)=> {
    // 正则表达式模式用于匹配字符串是否只包含英文和数字字符
    const pattern = /^[a-zA-Z0-9]+$/i;
    // 使用正则表达式测试输入的字符串
    return pattern.test(str);
}

export const checkPostCode = (str)=> {
    // 正则表达式模式用于匹配字符串是否只包含7位数字字符
    const pattern = /^\d{7}$/;
    // 使用正则表达式测试输入的字符串
    return pattern.test(str);
}

export const checkPhoneNumber = (str) =>{
    const pattern = /^\d{10}(\d{1})?$/;
    // 使用正则表达式测试输入的字符串
    return pattern.test(str);
}