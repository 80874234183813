import {get} from "@/domain/RestClient";

export const getLastInfoList = async () => {
    try {
        const response = await get("/info/lastlist");

        return response.data.result;
    } catch (error) {
        console.log(error)
    }
};

export const getInfoListAll = async () => {
    try {
        const response = await get("/info/listall");
        return response.data.result;
    } catch (error) {
        console.log(error)
    }
}

export const getInfoById = async (infoid) => {
    try {
        const response = await get("/info/detail/" + infoid);
        return response.data.info;
    } catch (error) {
        console.log(error)
    }
}
