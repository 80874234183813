import { render, staticRenderFns } from "./PhoneFoot.vue?vue&type=template&id=31be7164&scoped=true&"
import script from "./PhoneFoot.vue?vue&type=script&lang=js&"
export * from "./PhoneFoot.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "31be7164",
  null
  
)

export default component.exports