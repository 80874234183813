import {post} from "@/domain/RestClient";
import {getWithToken} from "@/domain/RestClient";

export const orderPost = async payload => {
    try {
        const response = await post("order/create", payload);
        return response;
    } catch (error) {
        console.log(error)
    }
};

export const getOrderList = async userId => {
    try {
        const response = await getWithToken("author/orderList/" + userId);
        return response.data.result;
    } catch (error) {
        console.log(error)
    }
};




