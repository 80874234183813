import { get } from "@/domain/RestClient";

export const getHolidayList = async() => {
    try {
        const response = await get("/holiday/list");
        return response;
    } catch (error) {
        console.log(error)
    }
};
