const namespaced = true;

const defaultData = {
    categoryList: [],
    selectCategoryName: null
};

const getState = () => ({
    ...defaultData
});

export default {
    namespaced,
    state: getState(),
    mutations: {
        setCategoryList(state, payload) {
            state.categoryList = payload.categoryList;
        },
        setSelectCategoryName(state, payload) {
            state.selectCategoryName = payload.selectCategoryName;
        },
        init(state) {
            Object.assign(state, defaultData);
        }
    },
    getters: {
        categoryList: state => state.categoryList,
        selectCategoryName: state => state.selectCategoryName
    },
    actions: {
        setCategoryList({commit}, {categoryList}) {
            commit("setCategoryList", {categoryList});
        },
        setSelectCategoryName({commit}, {selectCategoryName}) {
            commit("setSelectCategoryName", {selectCategoryName});
        },
        refresh({commit}) {
            commit("init");
        }
    }
}
