<template>
  <v-container style="max-width: 1280px;">
    <v-row style="max-height: 50px" cols="12" justify="start" align="center">
      <v-col cols="2" align="start" class="ml-3" @click="changeMenuFlag" style="padding-left: 15px;padding-top: 22px">
        <svg xmlns="http://www.w3.org/2000/svg" width="35px" height="35px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid meet">
          <!-- 填充背景颜色 -->
          <rect width="100%" height="100%" fill="white"/>

          <!-- 实际图像 -->
          <image xlink:href="../assets/menu.svg" x="10%" y="10%" width="80%" height="80%"/>
        </svg>
<!--        <img  style="margin-top: 10px;background-color:#777;margin-left: 10px" src="../assets/menu.svg" width="30px" height="35px">-->
      </v-col>
      <v-col cols="6" style="margin-right: 10px">
        <div @click="backToHome" style="cursor: pointer">
          <img height="45px" style="margin-top: 11px;" class="logo_size" src="../assets/logo.png">
        </div>
      </v-col>
      <v-col cols="1" style="margin-top: 5px">
        <v-btn
            width="40px"
            rounded
            color="pink"
            dark
            @click="goToCart"
            class="btn_menu"
        >
          <v-icon gray dark>
            mdi-cart
          </v-icon>
          <span class="cart_count">{{ goodItems.length }}</span>
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import {mapGetters} from "vuex";

export default {
  name: "Menu",
  computed:{
    ...mapGetters({
      goodItems: 'cart/goodItems'
    }),
  },
  methods: {
    changeMenuFlag(){
      this.$store.dispatch('menu/setMenuFlag', {menuFlag: true})
    },
    goToCart() {
      if (this.$route.path != '/phone/cart') {
        this.$router.push({
          name: "PhoneCartPage"
        });
      }
    },
    backToHome() {
      if (this.$route.path != '/phone') {
        this.$router.push({
          name: "PhoneHome"
        });
      }
    }
  }
}
</script>

<style scoped>
.logo_size {
  height: 35px;
  width: 200px;
}

.btn_menu {
  margin: 5px;
}
.cart_count {
  position: absolute;
  top: -10px;
  right: -10px;
  font-size: 12px;
  font-weight: bold;
  color: white;
  background-color: red;
  padding: 2px 5px;
  border-radius: 50%;
}
</style>
