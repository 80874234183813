<template>
  <v-container class="ma-0 pa-0">
    <v-row  style="background-color: whitesmoke;margin-top: 20px">
      <v-col cols="5">
        <img :src="urlImgHead+item.detail.imgurl" width="162px" height="121.5px"/>
      </v-col>
      <v-col cols="7">
        <div style="font-size: 16px">{{ item.detail.goodname }}</div>
        <div style="font-size: 16px"><b>¥{{ fixNumber(item.detail.price) }}</b></div>
        <v-row class="ma-0 pa-0">
          <v-col cols="10" class="ma-0 pa-0">
            <v-col cols="7" class="ma-0 pa-0">
              <span class="price_number" >数量：{{item.number}}</span>
            </v-col>
          </v-col>
        </v-row>
        <div>小計：￥{{ fixNumber(item.number * item.detail.price) }}</div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {urlImgHead} from "@/domain/RestClient"
import {fixPrice} from "@/utils/common";
import {mapGetters} from "vuex";

export default {
  name: "CartGoodShow",
  props: {
    item: Object
  },
  data() {
    return {
      urlImgHead
    }
  },
  computed: {
    ...mapGetters({
      goodItems: 'cart/goodItems',
    }),
  },
  methods: {
    fixNumber(price) {
      return fixPrice(price)
    },
    deleteItem(goodId) {
      for (let i = 0; i < this.goodItems.length; i++) {
        if (this.goodItems[i].goodid == goodId) {
          this.goodItems.splice(i, 1);
        }
      }
    },
  }
}
</script>

<style scoped>
.price_number input{
  text-align: center;
}
</style>
