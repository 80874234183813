import { render, staticRenderFns } from "./MessageCutShow.vue?vue&type=template&id=885f4088&scoped=true&"
import script from "./MessageCutShow.vue?vue&type=script&lang=js&"
export * from "./MessageCutShow.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "885f4088",
  null
  
)

export default component.exports