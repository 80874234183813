<template>
  <v-container class="pa-0 ma-0" style="z-index: 9999;position: relative">
    <v-card
        class="mx-auto"
        max-width="300"
        tile
        @mouseenter="showSubCategory"
        @mouseleave="closeSubCategory"
    >
      <v-list shaped dense>
        <v-list-item-group
            v-model="selectedItem"
            color="primary"
        >
          <v-list-item
              v-for="(item, i) in items"
              :key="i"
          >
            <v-list-item-content>
              <v-list-item-title v-text="item" @click="goodSearch(item)"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-card>
  </v-container>
</template>

<script>
import {mapGetters} from 'vuex'

export default {
  name: "SubSideCategoryMenu",
  data: () => ({
    selectedItem: null
  }),
  computed: {
    ...mapGetters({
      selectCategoryName: 'categoryInfo/selectCategoryName',
      categoryList: 'categoryInfo/categoryList'
    }),
    items: function () {
      let result = [];
      if (this.categoryList != null) {
        for (let i = 0; i < this.categoryList.length; i++) {
          if (this.categoryList[i].categoryName == this.selectCategoryName) {
            if (!result.includes(this.categoryList[i].categorySubName)) {
              result.push(this.categoryList[i].categorySubName)
            }
          }
        }
        return result
      } else {
        return [""]
      }

    }
  },
  methods: {
    closeSubCategory() {
      this.$emit('subCategorgShowStatus', false);
    },
    showSubCategory() {
      this.$emit('subCategorgShowStatus', true);
    },
    goodSearch(subCategoryName) {
      this.$store.dispatch('searchcondition/setCondition', {
        condition: {
          subCategoryName: subCategoryName,
          keyword: null
        }
      });
      if (this.$route.path == "/goodSearch") {
        window.location.reload();
      } else {
        this.$router.push({
          name: "GoodSearch"
        });
      }
    }
  }
}
</script>

<style scoped>

</style>
