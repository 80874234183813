<template>
  <v-container fluid>
    <v-row justify="center">
      <v-container style="max-width: 1280px">
        <v-row cols="12">
          <v-col cols="10" class="pa-0">
            <v-row class="mt-2">
              <v-col>
                <v-row class="pl-10">
                  <p class="titleBody"><b>特定商取引法に基づく表記</b></p>
                </v-row>
                <v-row class="pl-10">
                  <p class="contentBody"><b>販売業者</b></p>
                </v-row>
                <v-row class="pl-10">
                  <p class="contentBody">株式会社 源</p>
                </v-row>
                <v-row class="pl-10 mt-7">
                  <p class="contentBody"><b>運営統括責任者名</b></p>
                </v-row>
                <v-row class="pl-10">
                  <p class="contentBody">三石 祐樹</p>
                </v-row>
                <v-row class="pl-10 mt-7">
                  <p class="contentBody"><b>郵便番号</b></p>
                </v-row>
                <v-row class="pl-10">
                  <p class="contentBody">101-0023</p>
                </v-row>
                <v-row class="pl-10 mt-7">
                  <p class="contentBody"><b>住所</b></p>
                </v-row>
                <v-row class="pl-10">
                  <p class="contentBody">東京都千代田区神田松永町11 AＴ第一ビル5階</p>
                </v-row>
                <v-row class="pl-10 mt-7">
                  <p class="contentBody"><b>引渡し時期</b></p>
                </v-row>
                <v-row class="pl-10">
                  <p class="contentBody">15時までのご注文につきましては、当日出荷致します。</p>
                </v-row>
                <v-row class="pl-10">
                  <p class="contentBody">15時以降のご注文につきましては、翌営業日に出荷致します。 </p>
                </v-row>
                <v-row class="pl-10 mt-7">
                  <p class="contentBody"><b>お支払い方法</b></p>
                </v-row>
                <v-row class="pl-10">
                  <p class="contentBody">銀行振込・商品代引</p>
                </v-row>
                <v-row class="pl-10 mt-7">
                  <p class="contentBody"><b>お支払い期限</b></p>
                </v-row>
                <v-row class="pl-10">
                  <p class="contentBody">■3営業日以内にご入金が確認できない場合は、ご注文はキャンセルさせていただきます。</p>
                </v-row>
                <v-row class="pl-10">
                  <p class="contentBody">■お急ぎの方は「代金引換」をお選びください。</p>
                </v-row>
                <v-row class="pl-10 mt-7">
                  <p class="contentBody"><b>資格・免許</b></p>
                </v-row>
                <v-row class="pl-10">
                  <p class="contentBody">古物営業法 第306681406586号 東京都公安委員会</p>
                </v-row>
                <v-row class="pl-10 mt-7">
                  <p class="contentBody"><b>適格請求書発行事業者登録番号</b></p>
                </v-row>
                <v-row class="pl-10">
                  <p class="contentBody">T4011801027994</p>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-row>
  </v-container>
</template>

<script>
import 'vue-lunar-calendar-pro/dist/calendar.css'

export default {
  name: 'PhoneGoodInfo',
};
</script>
<style>
.titleBody{
  font-size: 24px;
}
.contentBody{
  font-size: 15px;
  margin-bottom: 5px !important;
}
</style>
