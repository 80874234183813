<template>
  <v-container>
    <v-dialog
        v-model="dialog"
        width="400"
    >
      <v-card shaped elevation="2">
        <v-card-title class="text-h5 lighten-2" style="background-color:#57acad">
          ログイン
        </v-card-title>
        <v-card-text style="margin-top: 30px;text-align: center;" class="pb-0">
          <v-text-field
              style="width: 300px !important;"
              outlined
              dense
              label="ログインID（メールアドレス）"
              class="mt-2"
              v-model="loginId"
          ></v-text-field>
          <v-text-field
              style="width: 300px !important;"
              counter="200"
              outlined
              dense
              label="パスワード"
              v-model="password"
          ></v-text-field>
        </v-card-text>
        <v-card-actions style="text-align: center">
          <v-spacer></v-spacer>
          <v-btn
              color="primary"
              text
              outlined
              @click="login"
          >
            ログイン
          </v-btn>
          <v-btn
              outlined
              color="red"
              text
              @click="dialog = false"
          >
            閉じる
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-row cols="12" justify="start" v-if="!this.subMenuFlag">
      <v-col cols="12">
        <div class="sideMenuFontSub" @click="backToHome">
          <v-icon gray dark>
            mdi-home
          </v-icon>
          ホーム
        </div>
      </v-col>
      <v-col cols="12" v-if="!loginStatus">
        <div class="sideMenuFontSub" @click="goToRegistPage">
          <v-icon gray dark>
            mdi-account-plus
          </v-icon>
          会員登録
        </div>
      </v-col>
      <v-col cols="12" v-if="!loginStatus">
        <div class="sideMenuFontSub" @click="openLoginDialog">
          <v-icon gray dark>
            mdi-account-check
          </v-icon>
          ログイン
        </div>
      </v-col>
      <v-col cols="12">
        <div class="sideMenuFontSub" @click="goToMyPage">
          <v-icon gray dark>
            mdi-account
          </v-icon>
          マイページ
        </div>
      </v-col>
      <v-col cols="12" v-if="loginStatus">
        <div class="sideMenuFontSub" @click="logout">
          <v-icon gray dark>
            mdi-login
          </v-icon>
          ログアウト
        </div>
      </v-col>
      <div style="width: 100%;height: 5px;background-color: whitesmoke"></div>
      <v-col cols="12" class="mb-0 pb-0">
        <div class="sideMenuFontSub" ref="sideMenu">
          <b>カテゴリから選ぶ</b>
        </div>
      </v-col>
      <v-col cols="12" >
        <div class="sideMenuFontSub" @click="goToSearch('パソコン')">
          <v-icon gray dark>
            mdi-monitor
          </v-icon>
          パソコン
        </div>
      </v-col>
      <v-col cols="12">
        <div class="sideMenuFontSub" @click="goToSearch('パソコンソフト')">
          <v-icon gray dark>
            mdi-microsoft
          </v-icon>
          パソコンソフト
        </div>
      </v-col>
      <v-col cols="12">
        <div class="sideMenuFontSub" @click="goToSearch('スマートフォン')">
          <v-icon gray dark>
            mdi-cellphone
          </v-icon>
          スマートフォン
        </div>
      </v-col>
      <v-col cols="12">
        <div class="sideMenuFontSub" @click="goToSearch('家電')">
          <v-icon gray dark>
            mdi-television
          </v-icon>
          家電
        </div>
      </v-col>
      <v-col cols="12">
        <div class="sideMenuFontSub" @click="goToSearch('化粧品')">
          <v-icon gray dark>
            mdi-lipstick
          </v-icon>
          化粧品
        </div>
      </v-col>
      <v-col cols="12" class="pb-0">
        <div class="sideMenuFontSub" @click="goToSearch('その他')">
          <v-icon gray dark>
            mdi-layers
          </v-icon>
          その他
        </div>
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col cols="12">
        <div class="sideMenuFontSub" @click="backToMenu">
          <v-icon gray dark>
            mdi-arrow-left
          </v-icon>
          <b>{{ showSubCategoryName }}</b>
        </div>
      </v-col>
      <v-col cols="12" class="pt-0">
        <div class="sideMenuFontSub" @click="goodSearch('すべて')">
         すべて
        </div>
      </v-col>
      <v-col v-for="(item,index) in this.showSubBody" :key="index" cols="12" class="pt-0">
        <div class="sideMenuFontSub" @click="goodSearch(item)">
          {{ item }}
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {mapGetters} from 'vuex'
import {login} from "@/domain/api/user";
import {getCategoryList} from "@/domain/api/category";

export default {
  name: "InsideMenu",
  data() {
    return {
      dialog: false,
      loginId: null,
      password: null,
      items: null,
      subMenuFlag: false,
      showSubCategoryName: "",
      showSubBody:[]
    }
  },
  async mounted() {
    this.subMenuFlag = false
    this.items = await getCategoryList();
  },
  computed: {
    ...mapGetters({
      token: 'logininfo/token',
      categoryList: 'categoryInfo/categoryList'
    }),
    loginStatus() {
      if (this.token == "") {
        return false;
      } else {
        return true;
      }
    }
  },
  methods: {
    goToSearch(targetName) {
      this.showSubCategoryName = targetName;
      this.showSubBody = [];
      const filteredCategories = this.categoryList
          .map(category => ({
            categoryName: category.categoryName,
            categorySubName: category.categorySubName
          })) // 转换为只包含categoryName和categorySubName属性的新对象
          .filter((category, index, self) => // 去重
              index === self.findIndex(c => c.categoryName === category.categoryName && c.categorySubName === category.categorySubName)
          );
      for (let i = 0; i < filteredCategories.length; i++) {
        if (targetName == filteredCategories[i].categoryName) {
          this.showSubBody.push(filteredCategories[i].categorySubName)
        }
      }
      this.subMenuFlag = true;
    },
    backToMenu() {
      this.subMenuFlag = false;
    },
    goodSearch(subCategoryName) {
      this.$store.dispatch('categoryInfo/setSelectCategoryName', {selectCategoryName: this.showSubCategoryName});
      this.$store.dispatch('menu/setMenuFlag', {menuFlag: false});
      this.$store.dispatch('searchcondition/setCondition', {
        condition: {
          subCategoryName: subCategoryName,
          keyword: null
        }
      });
      if (this.$route.path == "/phone/goodSearch") {
        window.location.reload();
      } else {
        this.$router.push({
          name: "PhoneGoodSearch"
        });
      }
    },
    async login() {
      try {
        let result = await login({
          loginId: this.loginId,
          password: this.password
        });
        this.dialog = false;
        this.$store.dispatch('logininfo/setLoginId', {
          loginId: this.loginId,
          token: result.data.token,
          userId: result.data.userId
        });
        this.loginId = "";
        this.password = "";
      } catch (e) {
        console.log(e);
        alert("ユーザID・パスワードが一致しません。");
      }
    },
    logout() {
      this.$store.dispatch('menu/setMenuFlag', {menuFlag: false});
      this.$store.dispatch('logininfo/refresh');
      if (this.$route.path != '/') {
        this.$router.push({
          name: "PhoneHome"
        });
      }
    },
    goToRegistPage() {
      this.$store.dispatch('menu/setMenuFlag', {menuFlag: false});
      if (this.$route.path != '/phone/registUer') {
        this.$router.push({
          name: "PhoneRegistUser"
        });
      }
    },
    openLoginDialog() {
      this.dialog = true
    },
    goToMyPage() {
      if (this.loginStatus) {
        if (this.$route.path != '/phone/myPage') {
          this.$store.dispatch('menu/setMenuFlag', {menuFlag: false});
          this.$router.push({
            name: "PhoneMyPage"
          });
        }
      } else {
        this.dialog = true;
      }

    },
    backToHome() {
      this.$store.dispatch('menu/setMenuFlag', {menuFlag: false});
      if (this.$route.path != '/phone') {
        this.$router.push({
          name: "PhoneHome"
        });
      }
    }
  }
}
</script>

<style scoped>
.sideMenuFont {
  font-size: 25px;
  color: white;
}

.sideMenuFontSub {
  font-size: 20px;
  color: white;
}

.logo_size {
  height: 80px;
  width: 250px;
}

.btn_menu {
  margin: 5px;
}
</style>
