import axios from "axios";
import store from '@/store/index'

export const urlImgHead="https://www.akiba109.com:82/"
// lambda用のAxiosWrapper
export const axiosWrapper = axios.create({
    baseURL: "https://www.akiba109.com:8080/",
    // baseURL: "http://localhost:8888/",
    timeout: 29000
});

export const getWithToken = async (path, config = {}) => {
    // let headers = {token: getToken()}
    let headers = {token: store.state.logininfo.token}
    config.headers = {
        ...config.headers,
        ...headers
    };
    try {
        const response = await axiosWrapper.get(path, config);
        return response;
    } catch (error) {
        console.log(error);
    }
};

export const postWithToken = async (path, orgData, config = {}) => {
        // let headers = {token: getToken()}
        let headers = {token: store.state.logininfo.token}
        config.headers = {
            ...config.headers,
            ...headers
        };
        // bodyにログ用のデータを追加する
        const data = orgData;
        try {
            const response = await axiosWrapper.post(path, data, config);
            return response;
        } catch (error) {
            console.log(error)
        }
    }
;

export const get = async (path, config = {}) => {
    try {
        const response = await axiosWrapper.get(path, config);
        return response;
    } catch (error) {
        console.log(error);
    }
};

export const post = async (path, orgData, config = {}) => {
        // bodyにログ用のデータを追加する
        const data = orgData;
        try {
            const response = await axiosWrapper.post(path, data, config);
            return response;
        } catch (error) {
            console.log(error)
        }
    }
;
