<template>
  <v-container>
    <v-row justify="center">
      <v-container style="max-width: 1280px">
        <v-row align="center">
          <v-col align="center" cols="12">
            <h1>会員情報更新</h1>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="3">
            <p>お名前</p>
          </v-col>
          <v-col cols="4">
            <v-text-field
                outlined
                dense
                label="姓"
                v-model="firstname"
                :error-messages="firstnameErrorMsg"
            ></v-text-field>
          </v-col>
          <v-col cols="4">
            <v-text-field
                outlined
                dense
                label="名"
                v-model="lastname"
                :error-messages="lastnameErrorMsg"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="3">
            <p>フリガナ</p>
          </v-col>
          <v-col cols="4">
            <v-text-field
                outlined
                dense
                label="セイ"
                v-model="firstnamekana"
                :error-messages="firstnamekanaErrorMsg"
            ></v-text-field>
          </v-col>
          <v-col cols="4">
            <v-text-field
                outlined
                dense
                label="メイ"
                v-model="lastnamekana"
                :error-messages="lastnamekanaErrorMsg"
            ></v-text-field>
          </v-col>
        </v-row>
<!--        <v-row dense>-->
<!--          <v-col cols="3">-->
<!--            <p>性別</p>-->
<!--          </v-col>-->
<!--          <v-col cols="7">-->
<!--            <v-radio-group row dense class="mt-0" v-model="sex">-->
<!--              <v-radio-->
<!--                  label="男"-->
<!--                  :value=0-->
<!--              ></v-radio>-->
<!--              <v-radio-->
<!--                  label="女"-->
<!--                  :value=1-->
<!--              ></v-radio>-->
<!--            </v-radio-group>-->
<!--          </v-col>-->
<!--        </v-row>-->
<!--        <v-row dense>-->
<!--          <v-col cols="3">-->
<!--            <p>生年月日</p>-->
<!--          </v-col>-->
<!--          <v-col cols="6">-->
<!--            <v-select-->
<!--                dense-->
<!--                :items="yearItems"-->
<!--                outlined-->
<!--                v-model="birthdayyear"-->
<!--            ></v-select>-->
<!--          </v-col>-->
<!--          <v-col cols="3"></v-col>-->
<!--          <v-col cols="3"></v-col>-->
<!--          <v-col cols="4">-->
<!--            <v-select-->
<!--                dense-->
<!--                :items="monthItems"-->
<!--                outlined-->
<!--                v-model="birthdaymonth"-->
<!--            ></v-select>-->
<!--          </v-col>-->
<!--          <v-col cols="4">-->
<!--            <v-select-->
<!--                dense-->
<!--                :items="dayItems"-->
<!--                outlined-->
<!--                v-model="birthdayday"-->
<!--            ></v-select>-->
<!--          </v-col>-->
<!--        </v-row>-->
        <v-row dense>
          <v-col cols="3">
            <p>郵便番号</p>
          </v-col>
          <v-col cols="4">
            <v-text-field
                style="max-width: 250px"
                outlined
                dense
                label="ハイフン不要"
                v-model="postcode"
                :error-messages="postCodeErrorMsg"
            ></v-text-field>
          </v-col>
          <v-col cols="2">
            <v-btn
                rounded
                color="blue"
                dark
                @click="autoAddress"
            >
              郵便番号検索
            </v-btn>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="3">
            <p>住所</p>
          </v-col>
          <v-col cols="8">
            <v-text-field
                style="max-width: 600px"
                outlined
                dense
                v-model="address" :error-messages="addressErrorMsg"
            ></v-text-field>
          </v-col>
          <v-col cols="3"></v-col>
          <v-col cols="8">
            <v-text-field
                style="max-width: 600px"
                outlined
                dense
                v-model="address1"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="3">
            <p>電話番号</p>
          </v-col>
          <v-col cols="8">
            <v-text-field
                outlined
                dense
                label="ハイフン（-）不要"
                v-model="phone"
                :error-messages="phoneErrorMsg"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="3">
            <p>メール通知を希望する</p>
          </v-col>
          <v-col cols="8">
            <v-checkbox dense label="クーポン、インベントなどの情報を登録したメールアドレスに送信する" v-model="mailsendflag">
            </v-checkbox>
          </v-col>
        </v-row>
        <v-row dense style="margin-top: 30px;">
          <v-col cols="6" align="center">
            <v-btn
                rounded
                color="gray"
                style="width:150px"
                @click="backToMyPage">
              マイページに戻る
            </v-btn>
          </v-col>
          <v-col cols="6" align="center">
            <v-btn
                rounded
                color="blue"
                dark
                style="width:100px"
                @click="updateUser"
            >
              更新
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-row>
  </v-container>
</template>

<script>
import {autoAddress, getUserInfo, updateUser} from '@/domain/api/user'
import {checkPhoneNumber, checkPostCode} from "@/utils/common";

export default {
  name: "UpdateUser",
  data() {
    return {
      yearItems: Array.from(Array(93), (v, k) => (k + 1930) + "年"),
      monthItems: Array.from(Array(12), (v, k) => k + 1 < 9 ? "0" + (k + 1) + "月" : (k + 1) + "月"),
      dayItems: Array.from(Array(31), (v, k) => k + 1 < 9 ? "0" + (k + 1) + "日" : (k + 1) + "日"),
      firstname: null,
      lastname: null,
      firstnamekana: null,
      lastnamekana: null,
      sex: Number(0),
      birthdayyear: new Date().getFullYear() + "年",
      birthdaymonth: (new Date().getMonth() + 1) + "月",
      birthdayday: (new Date().getDate()) + "日",
      postcode: null,
      phone: null,
      address: null,
      mailsendflag: false,
      firstnameErrorMsg: "",
      lastnameErrorMsg: "",
      firstnamekanaErrorMsg: "",
      lastnamekanaErrorMsg: "",
      sexErrorMsg: "",
      postCodeErrorMsg: "",
      phoneErrorMsg: "",
      addressErrorMsg: "",
      address1:""
    }
  },
  async mounted() {
    const userData = await getUserInfo(this.$store.state.logininfo.userId);
    this.firstname = userData.name.split(" ")[0];
    this.lastname = userData.name.split(" ")[1];
    this.firstnamekana = userData.namekana.split(" ")[0];
    this.lastnamekana = userData.namekana.split(" ")[1];
    this.sex = Number(userData.sex);
    this.birthdayyear = userData.birthday.substring(0, 4) + "年";
    this.birthdaymonth = userData.birthday.substring(4, 6) + "月";
    this.birthdayday = userData.birthday.substring(6, 8) + "日";
    this.postcode = userData.postcode;
    this.address = userData.address;
    this.phone = userData.phone;
    this.mailsendflag = userData.mailsendflag
  },
  methods: {
    async autoAddress() {
      this.address = await autoAddress(this.postcode);
    },
    backToMyPage() {
      this.$router.push({
        name: "PhoneMyPage"
      });
    },
    async updateUser() {
      let errorFlag = false;

      if (this.firstname == null || this.firstname == "") {
        this.firstnameErrorMsg = "姓を入力してください。"
        errorFlag = true;
      }
      if (this.lastname == null || this.lastname == "") {
        this.lastnameErrorMsg = "名を入力してください。"
        errorFlag = true;
      }
      if (this.firstnamekana == null || this.firstnamekana == "") {
        this.firstnamekanaErrorMsg = "セイを入力してください。"
        errorFlag = true;
      }
      if (this.lastnamekana == null || this.lastnamekana == "") {
        this.lastnamekanaErrorMsg = "メイを入力してください。"
        errorFlag = true;
      }
      if (this.postcode == null || this.postcode == "") {
        this.postCodeErrorMsg = "郵便番号を入力してください。"
        errorFlag = true;
      }
      if (!checkPostCode(this.postcode)) {
        this.postCodeErrorMsg = "郵便番号を入力してください。"
        errorFlag = true;
      }

      if (this.address == null || this.address == "") {
        this.addressErrorMsg = "住所を入力してください。"
        errorFlag = true;
      }
      if (this.phone == null) {
        this.phoneErrorMsg = "電話番号を入力してください。"
        errorFlag = true;
      }
      if (!checkPhoneNumber(this.phone)) {
        this.phoneErrorMsg = "電話番号を入力してください。"
        errorFlag = true;
      }

      if (errorFlag) {
        return;
      }

      await updateUser({
        mailaddress: this.$store.state.logininfo.loginId,
        name: this.firstname + " " + this.lastname,
        namekana: this.firstnamekana + " " + this.lastnamekana,
        sex: Number(this.sex),
        birthday: this.birthdayyear.replace("年", "") + this.birthdaymonth.replace("月", "") + this.birthdayday.replace("日", ""),
        postcode: this.postcode,
        address: this.address+this.address1,
        phone: this.phone,
        mailsendflag: Number(this.mailsendflag)
      });
      alert("更新を完了しました。")
      await this.$router.push({
        name: "PhoneMyPage"
      });
    }
  }
}
</script>

<style scoped>

</style>
