<template>
  <v-card style="margin-bottom: 30px">
    <v-card-title style="background-color: #F0F2F2!important;padding: 8px">
      <v-row dense>
        <v-col cols="3">
          <p class="title">注文番号</p>
        </v-col>
        <v-col cols="9">
          <p class="title">{{ this.orderDetail.orderid }}</p>
        </v-col>
        <v-col cols="3">
          <p class="title">注文日</p>
        </v-col>
        <v-col cols="9">
          <p class="title">{{ this.orderDetail.createdate }}</p>
        </v-col>
        <v-col cols="3">
          <p class="title">合計</p>
        </v-col>
        <v-col cols="9">
          <p class="title">{{ fixPrice(this.orderDetail.price+this.orderDetail.commission) }}円</p>
        </v-col>
        <v-col cols="3">
          <p class="title">お届け先</p>
        </v-col>
        <v-col cols="9">
          <p class="title">{{ this.orderDetail.address }}</p>
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text >
      <v-row align="center">
        <v-col cols="12">
          <v-row v-for="(goodDetail,index) in this.goodList" :key="index" class="pt-3">
            <v-col cols="4">
              <img :src="urlImgHead+goodDetail.detail.imgurl" width="90px" height="67.5px"/>
            </v-col>
            <v-col cols="8">
              <p style="font-size: 16px!important;" class="goodName">{{ goodDetail.detail.goodname }}</p>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12">
          <p style="font-size: 16px!important;">配送業者：{{ postMethodString(this.orderDetail.postmethod) }}</p>
          <p style="font-size: 16px!important;">追跡番号：{{ this.orderDetail.contactnumber }}</p>
          <p style="font-size: 16px!important;">注文ステータス：<v-chip>{{ this.showStatus(Number(this.orderDetail.status)) }}</v-chip></p>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { postMethodString,fixPrice } from "@/utils/common"
import { urlImgHead } from "@/domain/RestClient"

export default {
  name: "HistoryDetail",
  props: {
    orderDetail: Object,
  },
  data(){
    return{
      urlImgHead
    }
  },
  computed: {
    goodList() {
      return JSON.parse(this.orderDetail.goodlistjson);
    },

  },
  methods: {
    postMethodString,
    fixPrice,
    showStatus(statusCode) {
      if (statusCode == 0) {
        return "入金確認待ち"
      } else if (statusCode == 1) {
        return "発送待ち"
      } else if (statusCode == 2) {
        return "完了"
      } else {
        return "キャンセル"
      }
    }
  }
}
</script>

<style scoped>
.title {
  font-size: 16px !important;
  margin-bottom: 0px;
}

.goodName {
  font-size: 20px !important;
}
</style>
