<template>
  <v-container fluid>
    <v-row justify="center">
      <v-container style="max-width: 1280px">
        <v-row cols="12">
          <v-col cols="12" class="pa-0 ">
            <p style="font-size: 16px"><a href=".././">ホーム</a> ＞ お知らせ詳細</p>
          </v-col>
          <v-col cols="2"></v-col>
          <v-col cols="10" class="pa-0">
            <v-row style="margin-top: 25px">
              <v-col>
                <v-row class="pl-10">
                  <p style="font-size: 16px;margin-bottom: 0px">{{ infoMsg==null?'':infoMsg.createdate }}</p>
                </v-row>
                <v-row class="pl-10">
                  <p style="font-size: 18px">{{ infoMsg==null?'':infoMsg.title }}</p>
                </v-row>
                <v-row class="pl-10">
                  <span style="font-size: 18px;white-space: pre-wrap;">
                    {{ infoMsg==null?'':infoMsg.textbody.replaceAll('\\n','\n') }}
                  </span></v-row>
              </v-col>
            </v-row>
            <v-row justify="center" style="margin-top: 30px">
              <v-btn
                  width="200px"
                  color="primary"
                  dark
                  @click="goToInfoList"
              >
                お知らせ一覧に戻る
              </v-btn>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-row>
  </v-container>
</template>

<script>
import 'vue-lunar-calendar-pro/dist/calendar.css'
import {getInfoById} from "@/domain/api/info";


export default {
  name: 'InfoDetail',
  async mounted() {
    this.infoMsg = await getInfoById(this.$route.params.infoId);
    console.log(this.infoMsg)
  },
  data: () => ({
    infoMsg: null
  }),
  methods: {
    goToInfoList(){
      this.$router.push({
        name: "InfoList"
      });
    }
  }
};
</script>
<style>
.titleBody {
  font-size: 24px;
}

.contentBody {
  font-size: 15px;
  margin-bottom: 5px !important;
}
</style>
