const namespaced = true;

const defaultData = {
    menuFlag: false
};

const getState = () => ({
    ...defaultData
});

export default {
    namespaced,
    state: getState(),
    mutations: {
        setMenuFlag(state, payload) {
            state.menuFlag = payload.menuFlag;
        },
        init(state) {
            Object.assign(state, defaultData);
        }
    },
    getters: {
        menuFlag: state => state.menuFlag
    },
    actions: {
        setMenuFlag({commit}, {menuFlag}) {
            commit("setMenuFlag", {menuFlag});
        },
        refresh({commit}) {
            commit("init");
        }
    }
}
